<template>
  <div id="app">
    <template v-if="!fullscreen">
      <SideCart :isPanelOpen="activeCart" />
      <Navigation @remount="remount" />
      <FlashMessage v-if="showFlashMessage" />
    </template>

    <div
      :class="[
        showFlashMessage ? 'mt-8' : 'mt-nav',
        currentRoute == '/' ? 'mt-0' : '',
        fullscreen ? 'mt-0' : '',
      ]"
    >
      <router-view :blockRender="blockRender" @remount="remount" :key="key" />
    </div>

    <template v-if="!fullscreen">
      <!-- <a href="https://wa.me/+916000646445" target="_blank" class="whatsapp-chat-btn"><whatsapp fillColor="#25D366" /><span>
        {{ $mq == 'sm'? '' : 'WhatsApp Us' }}  
        </span> </a> -->
      <span class="chat-prompt">
        <fade-transition>
          <div v-if="showChatCallout" class="chat-callout-message">
            <span
              @click="$store.commit('SET_SHOW_CHAT_CALLOUT', false)"
              class="cancel cursor-pointer"
            >
              <WindowClose :size="18" />
            </span>
            Hi there! How may we help you? Tap the icon below to start chat
          </div>
        </fade-transition>
        <a
          href="https://wa.me/+916000646445"
          target="_blank"
          class="whatsapp-chat-btn"
        >
          <whatsapp :size="32" fillColor="#25D366" />
        </a>
      </span>

      <Preloader />
      <Footer v-if="!activeSearchField" />
    </template>

    <div class="loadingScreen" v-if="loading">
      <div>
        <page-loader size="32px" color="#6b6761"></page-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import shortid from "shortid";
import Navigation from "@/components/Navigation";
import SideCart from "@/components/SideCart";
import FlashMessage from "@/components/FlashMessage.vue";
import Footer from "@/components/Footer.vue";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import Preloader from "./components/Preloader.vue";
import axios from "axios";
import WindowClose from "vue-material-design-icons/WindowClose.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  metaInfo: {
    title: "Harshita Jain Design Studio - Official Website",
    titleTemplate: "%s | Harshita Jain",
  },
  data: () => ({
    key: null,
    blockRender: false,
  }),
  components: {
    Navigation,
    SideCart,
    FlashMessage,
    Footer,
    Whatsapp,
    Preloader,
    WindowClose,
    FadeTransition,
    "square-loader": SquareLoader,
  },
  created() {
    this.key = shortid.generate();

    axios
      .get(
        "https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/currencies.json"
      )
      .then((res) => {
        this.$store.commit("SET_CURRENCIES", res.data);
      });

      window.addEventListener('popstate', (evt) => {
        this.blockRender = true
        setTimeout(() => {
          this.$hidePageLoader()
          this.blockRender = false
        }, 2000);
      })
  },
  computed: {
    ...mapGetters([
      "activeCart",
      "loading",
      "fullscreen",
      "showChatCallout",
      "activeSearchField",
    ]),
    currentRoute() {
      return this.$route.path;
    },
    currentRouteObj() {
      return this.$route
    },
    showFlashMessage() {
      return this.$route.meta.showFlashMessage;
    },
  },
  methods: {
    remount() {
      console.log("remounting...");
      this.key = shortid.generate();
    },
  },
  watch: {
    activeSearchField(val) {
      if (!val) {
        this.$hidePageLoader();
      }
    },
    // currentRouteObj: {
      // this.$hidePageLoader()
    // }
  },
};
</script>


<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-default {
  border: 1px solid #d7cec2;
  outline: none;
  width: 100%;
  padding: 10px 0px;
  background-color: #d7cec2;
  letter-spacing: 2px;
  color: #56524d;
  cursor: pointer;
}

.btn-outline {
  border: 1px solid #d7cec2;
  background-color: white;
  color: #6b6761;
  cursor: pointer;
}

.multiselect__tag,
.multiselect__option--highlight {
  background: #d7cec2 !important;
  color: black !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: rgb(245, 81, 81) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.mt-8 {
  margin-top: 125px;
}

.mt-nav {
  margin-top: 95px;
}

.text-muted {
  color: #6d6767;
}

.font-normal {
  font-weight: normal;
}

.font-strikethrough {
  text-decoration: line-through
}

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  background: white;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-prompt {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.chat-callout-message {
  padding: 15px;
  background: white;
  margin-bottom: 12px;
  max-width: 270px;
  font-size: 14px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  text-decoration: none;
}

.chat-callout-message > .cancel {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
}

.chat-callout-message > .cancel > span {
  margin-bottom: 5px;
}

.whatsapp-chat-btn {
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  text-decoration: none;
}

.whatsapp-chat-btn > span {
  margin-left: 2px;
  line-height: 100%;
}

@media (max-width: 700px) {
  .mt-8 {
    margin-top: 100px;
  }
  .mt-nav {
    margin-top: 55px;
  }
}
</style>
