import firebase from "firebase/app"
import 'firebase/auth'
// import 'firebase/database'

const firebaseConfig = {
  apiKey: "AIzaSyBk80gXIlzlyG5UXBx5gLW10fEFMl3WRi0",
  authDomain: "harshita-jain.firebaseapp.com",
  projectId: "harshita-jain",
  storageBucket: "harshita-jain.appspot.com",
  messagingSenderId: "263612416845",
  appId: "1:263612416845:web:5bc9c236717ab193dfad04",
  measurementId: "G-2Y3WGK22CS"
};

!firebase.apps.length? firebase.initializeApp(firebaseConfig) : ''

// export const auth = firebase.auth()
export default firebase