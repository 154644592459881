<template>
  <div class="address" style="display: flex">
    <div
      class="
        empty-cart
        d-flex
        justify-content-center
        flex-fill
        align-items-center
      "
      v-if="!cart.length"
    >
      <div class="text-center mb-5">
        <a href="#" title="Image from freeiconspng.com"
          ><img
            src="https://www.freeiconspng.com/uploads/basket-cart-icon-27.png"
            width="50"
            alt="basket cart icon"
        /></a>
        <h1>YOUR CART IS EMPTY</h1>
        <router-link to="/" class="d-block mt-3">
          <u>Return to Shopping</u>
        </router-link>
      </div>
    </div>
    <div v-else class="container-fluid flex-fill">
      <div class="row" style="height: 100%">
        <div class="col-md-7 pl-100 order-md-1 order-2">
          <div class="steps">
            <span>Home > <span class="active">Address</span> > Shipping</span>
          </div>
          <h1>Contact Information</h1>
          <form @submit.prevent="saveAddress">
            <input
              type="text"
              v-model="personInfo.email"
              placeholder="Enter your email address"
              class="field-input"
            />
            <div class="address-wrapper">
              <div class="row">
                <div class="col-md-6 mt-3 pr-md-2">
                  <input
                    type="text"
                    placeholder="First Name"
                    v-model="personInfo.firstName"
                    class="field-input"
                  />
                </div>
                <div class="col-md-6 mt-3 pl-md-2">
                  <input
                    type="text"
                    placeholder="Last Name"
                    v-model="personInfo.lastName"
                    class="field-input"
                  />
                </div>
                <div class="col-md-12 mt-3">
                  <input
                    type="text"
                    placeholder="Address"
                    v-model="personInfo.address"
                    class="field-input"
                  />
                </div>
                <div class="col-md-12 mt-3">
                  <input
                    type="text"
                    placeholder="Apartment, suite,etc (optional)"
                    v-model="personInfo.optionalAddress"
                    class="field-input"
                  />
                </div>
                <div class="col-md-12 mt-3">
                  <input
                    type="text"
                    placeholder="City"
                    v-model="personInfo.city"
                    class="field-input"
                  />
                </div>
                <div class="col-md-4 mt-3">
                  <input
                    type="text"
                    placeholder="Country"
                    v-model="personInfo.country"
                    class="field-input"
                  />
                </div>
                <div class="col-md-4 px-md-1 mt-3">
                  <input
                    type="text"
                    placeholder="State"
                    v-model="personInfo.state"
                    class="field-input"
                  />
                </div>
                <div class="col-md-4 mt-3">
                  <input
                    type="text"
                    placeholder="PIN Code"
                    v-model="personInfo.pinCode"
                    class="field-input"
                  />
                </div>
                <div class="col-md-12 mt-3">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    v-model="personInfo.phoneNumber"
                    class="field-input"
                  />
                </div>
              </div>
              <!-- ERROR DISPLAY  -->
              <div class="row" v-if="errors.length">
                <div class="col-12">
                  <ul>
                    <li v-for="(error, index) in errors" :key="index">
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </div>
              <!-- ACTION BUTTON  -->
              <div class="row mt-5 no-gutters">
                <div class="col-6 pr-1">
                  <button
                    @click="$router.back()"
                    class="btn-default btn-outline"
                  >
                    Go Back
                  </button>
                </div>
                <div class="col-6 pl-1">
                  <button type="submit" class="btn-default">Continue</button>
                </div>
              </div>
            </div>
          </form>
        </div>

          <OrderSummary :hasDeliveryCharge="false"/>
        <!-- <div
          class="col-md-5 px-0 order-1 order-md-2"
          @click="toggleOrderSummary"
        >
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import { getCoupon } from "../api/coupons";
import OrderSummary from '../components/OrderSummary.vue'

export default {
  components:{
    OrderSummary
  },
  data() {
    return {
      errors: [],
      showOrder: false,
      coupon: null,
      couponCode: "",
      personInfo: {
        firstName: null,
        lastName: "",
        address: "",
        optionalAddress: "",
        city: "",
        country: "",
        state: "",
        pinCode: "",
        phoneNumber: "",
      },
    };
  },
  created() {
    let personInfo = this.$store.getters.personInfo;
    if (!!personInfo) {
      this.personInfo = Object.assign({}, personInfo);
    }
  },
  computed: {
    ...mapGetters([
      "cart",
      "cartTotal",
      "grandTotal",
      "discount",
      "discountAmount",
    ]),
    orderSummaryText() {
      return this.showOrder ? "Hide Order Summary" : "Show Order Summary";
    },
  },
  methods: {
    saveAddress() {
      this.errors = [];
      if (this.formValidation() && this.cart.length) {
        console.log("✅ Form validation OK");
        this.$store.dispatch("personInfo", this.personInfo);
        this.$router.push("/shipping");
      } else {
        console.log("❌ FORM VALIDATION ERROR");
      }
    },
    async applyCoupon() {
      try {
        const discount = await getCoupon(this.couponCode);
        this.$store.commit("SET_DISCOUNT", { ...discount, applied: true });
        // console.log(discount)
        // if(this.cartTotal < discount.minAmount) {
        //   console.log("please add at least " + discount.minAmount)
        //   return
        // }
        // const discountAmount = this.cartTotal * (discount.rate * 0.01)
        // this.discountProp.appliedDiscount = discountAmount >= discount.maxDiscount ? discount.maxDiscount : discountAmount
        // this.grandTotal =  discountAmount >= discount.maxDiscount? this.cartTotal - discount.maxDiscount : this.cartTotal - discountAmount
      } catch (error) {
        console.log(error);
      }
    },
    toggleOrderSummary() {
      console.log(this.showOrder);
      this.showOrder = !this.showOrder;
    },

    // FORM VALIDATION
    formValidation() {
      if (!this.cart.length) {
        this.errors.push("Your Cart is Empty");
      }

      if (!this.personInfo.firstName || !this.personInfo.lastName) {
        this.errors.push("Name Required");
      }

      if (!this.personInfo.email) {
        this.errors.push("Email Required");
      } else if (!this.validEmail(this.personInfo.email)) {
        this.errors.push("Valid email required.");
      }

      if (!this.personInfo.address) {
        this.errors.push("address required");
      }
      if (!this.personInfo.city) {
        this.errors.push("city required");
      }

      if (!this.personInfo.phoneNumber) {
        this.errors.push("phone number required");
      }

      if (!this.errors.length) {
        return true;
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    // END FORM VALIDATION
  },
  components: {
    ChevronDown,
    ChevronUp,
    OrderSummary
  },
};
</script>

<style scoped>
.address {
  display: flex;
  min-height: 100vh;
}
.shop-cart {
  /* background: rgb(241, 241, 241); */
  background-color: #f1ede9;
  /* height: 100vh; */
  height: 100%;
}

.address h1 {
  font-size: 30px;
  font-weight: normal;
}

.pl-100 {
  padding-left: 100px;
}

.field-input {
  border: 1px #eee solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 0.9285714286em 0.7857142857em;
  word-break: normal;
  line-height: inherit;
}

.address-wrapper {
  margin-top: 0px;
  margin-bottom: 20px;
}

.item-cart-list {
  padding: 30px 100px 20px 30px;
}

.item-card {
  height: 100px;
  /* background: rgb(248, 246, 246); */
  display: flex;
  margin-top: 10px;
  border: 1px solid #ccc;
}

.item-image,
.item-price {
  display: inline-block;
}

.item-image {
  height: 100%;
  width: 120px;
  background: #ccc;
  overflow: hidden;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.line {
  margin: 20px 0px;
  height: 1px;
  border: 0;
  background-color: #ccc;
}

.item-price {
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

.steps {
  margin-top: 20px;
  font-size: 10px;
  text-transform: uppercase;
}

.active {
  font-weight: bold;
}

.shipping,
.subtotal {
  font-size: 14px;
}

.total {
  font-weight: bold;
  font-size: 20px;
  font-weight: normal;
}

.item-detail {
  font-size: 11px;
}

.order-summary {
  margin-top: 10px;
}

.coupon-input {
  border: 1px solid #ccc;
  padding: 10px;
}

.coupon-input:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .pl-100 {
    padding-left: 15px;
  }

  .btn-outline {
    /* margin-top: 10px; */
  }

  .item-cart-list {
    padding: 10px 20px;
  }

  .hide-summary {
    display: none;
  }

  .show-summary {
    display: block !important;
  }
}
</style>
