import firebase from "./firebase";
import "firebase/functions"

export const createNewOrder = firebase.app().functions('asia-east2').httpsCallable("createNewOrder");
export const makeOnlinePayment = firebase.app().functions('asia-east2').httpsCallable("makeOnlinePayment");
export const verifyPayment = firebase.app().functions('asia-east2').httpsCallable("verifyPayment");
export const testFunc = firebase.app().functions('asia-east2').httpsCallable("testFunc");

// EMULATIONS...
// firebase.functions().useEmulator("localhost", 5001);
// export const testFn = firebase.functions().httpsCallable("test");
// export const verifyPayment = firebase.functions().httpsCallable("verifyPayment");
// export const makeOnlinePayment = firebase.functions().httpsCallable("makeOnlinePayment");