<template>
  <div v-if="showPreloader">
    <modal :dismissable="false" @closeChart="$store.commit('SET_SHOW_PRELOADER', false)"> 
      <template v-slot:body>
        <div class="row">          
          <div class="col-12 mt-2 mb-3 text-center d-flex justify-content-center align-items-center">
            <MoonLoader color="#403d3a" size="30px" />
            <span class="pl-3">
              {{ text }}
            </span>
          </div>
        </div>
        <!-- <div>
          <button @click="$store.commit('SET_SHOW_PRELOADER', false)">HIDE</button>
        </div> -->
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'
import { mapGetters } from 'vuex'
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  props: {
    text: {
      default: 'Loading. Please wait.',
      required: false
    }
  },
  computed: {
    ...mapGetters(['showPreloader'])
  },
  components: {
    Modal,
    MoonLoader
  }
}
</script>