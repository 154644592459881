<template>
  <div class="product-slider mb-md-4">
    <flickity ref="flickity" :options="flickityOptions">
      <div class="carousel-cell" v-for="(image, index) in images" :key="index">
        <img @click="openPreview(index)" :src="image" width="100%" alt="" />
        <!-- <inner-image-zoom 
      :src="image"
      :zoomSrc="image"
      zoomType="click"
      :zoomScale = "1.5"
      hideHint: false
    /> -->
      </div>
    </flickity>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
// import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
// import InnerImageZoom from "vue-inner-image-zoom";

export default {  
  components: {
    Flickity,
    // "inner-image-zoom": InnerImageZoom,
  },
  props: {
    images: {
      type: Array,
    },
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true,
        autoPlay: false,
        
        // contain: true
        // any options from Flickity can be used
      },
    };
  },
  methods: {
    openPreview(index) {     
      this.$store.commit("SET_DISABLE_LOADING", true) 
      this.$router.push(this.$route.path+'/preview?imgIndex='+index)
    }
  },
  beforeMount() {
    if(this.$mq == 'sm') { 
      this.flickityOptions.pageDots = false
    }
  },
};
</script>

<style scoped>
.carousel-cell {
  width: 470px;
  height: 700px;
  margin-right: 10px;
  background: #eee;
  border-radius: 5px;
  counter-increment: gallery-cell;
}

.carousel-cell img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.btn-default {
  border: 1px solid #626262;
  outline: none;
  padding: 10px 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: transparent;
}

.iiz__hint {
    bottom: 10px;
    right: 10px;
    pointer-events: none;
    display: none !important;
}


@media(max-width:1366px){
  .carousel-cell {
    height: 580px;
    width: 400px;
  }
}

@media(max-width:1024px){
  .carousel-cell {
  width: 440px;
  height: 700px;
}
}

@media (max-width: 767px) {
  .product-slider {
    margin-left: -15px;
    margin-right: -15px;
  }
  .carousel-cell {
    width: 80vw;
    height: 60vh;
    margin-right: 5px;
    background: #eee;
    border-radius: 5px;
    counter-increment: gallery-cell;
  }
  .carousel-cell img {
    height: 100%;
    object-fit: cover;
  }
}
</style>
