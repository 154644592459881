<template>
  <div
    class="success-order d-flex flex-column justify-content-center align-items-center"
  >
    <div>
      <!-- <img :src="require('@/assets/sucess.gif')" alt="" width="80px" /> -->
      
      <div class="text-center mt-3">
        <span class="animate__animated animate__zoomIn">
          <CheckCircle :size="54" />
        </span>
      </div>

      <h1 style="font-size:26px;" class="text-center">
        Order Placed Successfully!
      </h1>
      <span class="text-center d-block">
        Thank you for shopping at Harshita Jain. <br />
        A confirmation has been send to your email.
      </span>
      <!-- {{ personInfo }} -->


      <div class="px-3">
        <div class="mt-4">
          <p class="text-center"><strong>Order Summary</strong></p>
          <table>
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
            <tr v-for="(item, index) in cartLocal" :key="item.id + '-' + index">
              <td>{{ item.name }}</td>
              <td class="text-right">{{ item.quantity }}</td>
              <td class="text-right">{{ item.price }}</td>
            </tr>
            <tr><th>Total:</th><td class="text-right" colspan="2">{{ cartTotalLocal }}</td></tr>
          </table>
        </div>
        <div class="shipping-info">
          <h3 style="font-size:16px;">Shipping Info:</h3>
          <p>
            {{ personInfo.address }},
            <span v-if="personInfo.optionalAddress"
              >{{ personInfo.optionalAddress }},</span
            >
            {{ personInfo.city }},{{
                personInfo.state
              }}, {{ personInfo.pinCode }}, {{ personInfo.country }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CheckCircle from 'vue-material-design-icons/CheckCircle.vue'

export default {
  data(){
    return{
      cartLocal: [],
      cartTotalLocal: 0
    }
  },
  computed: {
    ...mapGetters(["cart", "cartTotal", "personInfo"]),
    // cartTotalLocal(){
    //   return this.cartTotal
    // }
  },
  components: {
    CheckCircle
  },
  destroyed() {
    // this.$store.commit("EMPTY_CART")    
  },
  created(){
    this.cartLocal = [...this.cart]
    this.cartTotalLocal = this.cartTotal
    this.$store.commit("EMPTY_CART")
  }
};
</script>

<style scoped>
.success-order {
  min-height: 70vh;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f1ede9;
}

.shipping-info {
  margin-top: 10px;
  border: 1px solid #dddd;
  padding: 0px 10px;
  margin-bottom: 20px;
}
</style>
