<template>
  <div class="home">
    <Hero />
    <Intro />
    <NewArrivals @loaded="updateHomeLoadState('new-arrival')" />
    <BannerOne />    
    <HomeSlider @loaded="updateHomeLoadState('hero')" />
    <FeaturedCollections v-if="!blockRender" @loaded="updateHomeLoadState('featuredCategories')" />
    <HomeShipping />
    <HomeCategory @loaded="updateHomeLoadState('categories')" />
    <!-- <Collection /> -->
    <!-- <BannerTwo /> -->
  </div>
</template>

<script>
import Hero from '@/components/Hero'
import Intro from '@/components/Intro'
import HomeSlider from '@/components/HomeSlider'
import BannerOne from '@/components/BannerOne'
import HomeCategory from '@/components/HomeCategory'
import HomeShipping from '@/components/HomeShipping'
import Collection from '@/components/Collection'
import FeaturedCollections from '@/components/FeaturedCollections'
import NewArrivals from '@/components/NewArrivals'

export default {
  props: {
    blockRender: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    componentsLoaded: []
  }),
  components: {
    Hero,
    Intro,
    HomeSlider,
    BannerOne,
    HomeCategory,
    HomeShipping,
    // BannerTwo,
    Collection,
    FeaturedCollections,
    NewArrivals
  },  
  methods: {
    updateHomeLoadState(component) {
      this.componentsLoaded.push(component)
      if(this.componentsLoaded.length == 4) { 
        this.$store.commit("SET_LOADING_STATE", false)
      }
    },
  }
}
</script>