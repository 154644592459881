<template>
  <div class="hero">
    <div v-if="!!heroImg.length">
      <agile :autoplay="true" :fade="false" :autoplaySpeed="5000" :nav-buttons="false">
        <div @click="$router.push('/collections/mayim-garden-2.0')" class="slide slide-prime" v-for="(hero, index) in heroImg" :key="index">
          <!-- <img :src="hero.deskImg" alt :srcset="`${hero.deskImg} 1x, ${hero.mobileImg} 720w`" /> -->
          <img :src="getSlideUrl(hero)" />
          <div class="explore-btn-wrapper">
            <button>
              EXPLORE COLLECTION
            </button>
          </div>    
          <div class="slide-name">
            Mayim Garden 2.0
          </div>      
        </div>
        <!-- <div class="slide slide-prime">
          <img src="@/assets/hero_bg_2.jpg" alt srcset />
        </div>-->
      </agile>
    </div>    
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import { getCarousel } from "../api/carousel";

export default {
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      heroImg: []
    }
  },
  async created() {
    let carousel = await getCarousel()
    this.heroImg = carousel.map(item => ({
      deskImg: item.desktopUrl,
      // mobileImg: item.url
      mobileImg: item.desktopUrl
    }))
  },
  methods: {
    getSlideUrl(hero) {
      if (this.$mq == 'sm' || this.$mq == 'sm_md') {
        return hero.mobileImg
      }
      return hero.deskImg
    }
  }
};
</script>

<style>
.hero {
  /* height: 100vh; */
  position: relative;
  /* display: flex; */
  max-width: 100vw;
}

.slide-name {
  position: absolute;  
  left: 40px;
  bottom:40px;
  z-index: 2;  
  font-size: 24px;
  color: white;
  font-family: 'Baskerville';
}

.explore-btn-wrapper {
  position: absolute;
  height: 20%;
  top: 45%;
  z-index: 2;
  object-fit: contain;
  max-width: 100vw;
  /* transform: translateY(-50%) ; */
}

.explore-btn-wrapper button {
  background: transparent;  
  outline: none;
  border: 2px solid #d7cec2;
  border-right-color: transparent;  
  border-left-color: transparent;  
  padding: 25px 50px;
  color: #d7cec2;
  font-size: 20px;
  /* font-weight: bold; */
  cursor: pointer;
  letter-spacing: 3px;
}

.slide-prime::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.hero img {
  width: 100%;
  height: 100%;
  object-position: top;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 80px;
}
.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}
.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;  
}

.slide-prime {
  position: relative;
  z-index: 0;
}

.slide-prime img {
  object-fit: cover;
  object-position: center;
}

.text-box {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.text-box h2 {
  font-size: 96px;
  letter-spacing: 2px;
  color: white;
  margin-bottom: 30px;
}

.hero-btn {
  border: 1px solid #626262;
  outline: none;
  font-size: 24px;
  padding: 20px 75px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: black;
  color: white;
  cursor: pointer;
}

@media (max-width: 1366px) {
  .text-box h2 {
    font-size: 64px;
    letter-spacing: 2px;
    color: white;
    margin-bottom: 30px;
  }

  .hero-btn {
    font-size: 16px;
    padding: 15px 30px;
  }

  .hero-text {
    top: 10%;
  }
}

@media (max-width: 1024px) {
  .text-box {
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translate(0, -50%);
    z-index: 5;
  }

  .text-box h2 {
    font-size: 46px;
    letter-spacing: 2px;
    color: white;
    margin-bottom: 30px;
  }

  .slide {
    height: 67vh;
  }

  .hero-text {
    top: 30%;
    object-fit: contain;
    /* transform: translateY(-50%) ; */
  }
}

@media (max-width: 768px) {
  .hero-text {
    top: 40%;
    object-fit: contain;
    /* transform: translateY(-50%) ; */
  }
}
</style>
