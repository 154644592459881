import { couponsRef } from './../services/database'

export const getCoupon = (code) => {
    return new Promise((resolve, reject) => {
        
        let ref = couponsRef.where("code", "==", code)
        
				ref
        .get()
        .then(snaps => {        
						resolve(snaps.docs[0].data())
        })
        .catch(e => reject(e)) 
    })
}

// export async function getProduct(slug) {

//     let ref = productsRef.where("slug", "==", slug)    
//     try {
//         let snap = await ref.get()
//         return Promise.resolve(snap.empty? {} : snap.docs[0].data())
//     } catch (e) {
//         return Promise.reject(e)
//     }
//   }