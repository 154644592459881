<template>
  <div
    class="navigation"
    :class="[
      isSticky ? stickyClass : '',
      currentRoute != '/' ? 'navborder' : '',
      isNavStatic ? 'toggle-colors' : '',
    ]"
    v-show="showNav"
  >
    <!-- <div class="navigation">         -->
    <div v-if="activeSearchField" class="search-wrapper">
      <Magnify :fillColor="colorVal" />
      <input
        ref="searchField"
        type="text"
        placeholder="Search Product"
        :value="searchQuery"
        @input="(evt) => (searchQuery = evt.target.value)"
      />
      <WindowClose @click="cancelSearchField()" />
      <!-- <span class="search-cancel" type="button" @click="cancelSearchField()">Cancel</span> -->
    </div>
    <nav v-else class="navbar d-none d-lg-block">
      <div class="d-flex justify-content-between align-items-center">
        <!-- <div class="social-icons">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/StayHighAdventures/" target="_">
                                <img class="social-icon" src="../assets/svg/facebook.svg" width="25px" alt="">
                            </a>
                        </li>  
                        <li>
                            <a href="https://www.instagram.com/stayhigh.adventures/" target="_">
                                <img class="social-icon" src="../assets/svg/instagram.svg" width="25px" alt="">
                            </a>
                        </li>     
                        <li>
                            <div>
                                <a href="https://www.youtube.com/channel/UCbwLWw5Cw9JHcS5ca2w0IgA" target="_">
                                    <img class="social-icon" src="../assets/svg/youtube.svg" width="25px" alt="">
                                </a>
                            </div>
                        </li>                 
                    </ul>
                </div>  -->
        <div class="logo ml-5 pl-5">
          <router-link to="/">
            <img
              v-if="isSticky || isNavStatic"
              class="mt-2 ml-2"
              width="160"
              :src="require('@/assets/logo/logo-dark.png')"
              alt=""
            />
            <img
              v-else
              class="mt-2 ml-2"
              width="160"
              :src="require('@/assets/logo/harshita-jain-logo-white.png')"
              alt=""
            />
          </router-link>
          <!-- <img src="https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/stayhigh_hero_bg_video.mp4?alt=media&token=2c58433c-ed93-409b-9dbe-ca5335f338e1" width="150" alt=""> -->
        </div>

        <div>
          <ul class="ml-5">
            <li class="nav-link">
              <router-link to="/">Home </router-link>
            </li>
            <li class="nav-link">
              <!-- <router-link to="/collections/women">Women</router-link> -->
              <router-link to="/collections/women" @click.native="$emit('remount')">
                Women
              </router-link>
              <div class="drop-down">
                <div class="drop-cat-items">
                  <div
                    class="drop-cat"
                    v-for="category in categories"
                    :key="category.id"
                  >
                    <div class="circle" @click="goToCategory(category.slug)">
                      <img
                        :src="
                          !!category.image
                            ? category.image
                            : require('@/assets/homeslider/slide2.jpg')
                        "
                        alt=""
                      />
                    </div>
                    <h5 class="m-0 mt-2" @click="goToCategory(category.slug)">
                      {{ category.name }}
                    </h5>
                  </div>
                </div>
              </div>
            </li>
            <!-- <li class="nav-link">
              <router-link to="#">Men</router-link>
              <div class="drop-down">
                <div class="drop-cat-items">
                  <div
                    class="drop-cat"
                    v-for="item in dropdown.men"
                    :key="item.name"
                  >
                    <div class="circle">
                      <img src="@/assets/collections/collection2.jpg" alt="" />
                    </div>
                  <router-link to="/about">
                    <h5 class="m-0 mt-2">{{ item.name }}</h5>
                  </router-link>
                  </div>

                </div>
              </div>
            </li> -->
            <!-- <li class="nav-link">
              <router-link to="/collections/jewellery">Jewellery</router-link>
            </li> -->
            <li class="nav-link">
              <router-link to="/about">About</router-link>
            </li>
            <li class="nav-link">
              <router-link to="/contact">Contact</router-link>
            </li>
            <!-- <button @click="openSearchField()">Search</button> -->
            <li>
              <select
                @change="handleCurrencyChange($event)"
                name=""
                id=""
                :value="currency"
                class="currency-select"
                :style="{ color: isNavStatic ? '#403d3a' : '' }"
              >
                <option
                  v-for="currency in currencies"
                  :key="currency.name"
                  :value="currency.name"
                >
                  {{ currency.symbol }} {{ currency.name }}
                </option>
              </select>
            </li>
            <li>
              <span class="cursor-pointer" @click="openSearchField">
                <Magnify :fillColor="colorVal" />
              </span>
            </li>
            <li>
              <div
                @click="$store.commit('ACTIVE_CART', true)"
                class="d-inline-block nav-cart"
              >
                <CartOutline :fillColor="colorVal" />
                <div
                  v-if="!!cart.length"
                  class="d-inline-block nav-cart-indicator"
                >
                  <CheckboxBlankCircle :fillColor="colorVal" />
                </div>
              </div>
            </li>
            <!-- <li>About</li> -->
          </ul>
        </div>
      </div>
    </nav>

    <!-- **** MOBILE NAVIGATION **** -->
    <nav v-if="!activeSearchField">
      <div
        class="mobile-nav"
        :class="[
          isSticky ? 'mobile-nav-alt' : '',
          isNavStatic ? 'toggle-colors' : '',
        ]"
      >
        <div class="menu">
          <!-- <Menu :size="26" :fillColor="colorVal? '#5D574F': 'white'" /> -->
          <Menu :size="26" :fillColor="colorVal" @click="openMobileNav()" />
          &nbsp;
          <router-link to="/" custom>
            <img
              v-if="isSticky || isNavStatic"
              class="mt-2 ml-2"
              width="160"
              :src="require('@/assets/logo/logo-dark.png')"
              alt=""
            />
            <img
              v-else
              class="mt-2 ml-2"
              width="160"
              :src="require('@/assets/logo/harshita-jain-logo-white.png')"
              alt=""
            />
          </router-link>
        </div>
        <div class="cart-section">
          <Magnify @click="openSearchField" :fillColor="colorVal" />
          <div
            @click="$store.commit('ACTIVE_CART', true)"
            class="d-inline-block"
          >
            <CartOutline :fillColor="colorVal" />
            <div
              v-if="!!cart.length"
              class="d-inline-block"
              style="
                position: absolute;
                z-index: 9;
                right: -2px;
                \;top: 5px;
                transform: scale(0.6);
              "
            >
              <CheckboxBlankCircle :fillColor="colorVal" />
            </div>
          </div>
        </div>
      </div>
    </nav>
    <MobileNavigation
      @remount="$emit('remount')"
      :isPanelOpen="openNav"
      @closeMobileNav="closeMobileNav"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "vue-material-design-icons/Menu.vue";
import Magnify from "vue-material-design-icons/Magnify.vue";
import WindowClose from "vue-material-design-icons/WindowClose.vue";
import CartOutline from "vue-material-design-icons/CartOutline.vue";
import MobileNavigation from "./MobileNavigation.vue";
import CheckboxBlankCircle from "vue-material-design-icons/CheckboxBlankCircle.vue";
import { FadeTransition } from "vue2-transitions";
import { listCategories } from "@/api/categories";

export default {
  data() {
    return {
      categories: [],
      isSticky: false,
      scrollPosition: 0,
      stickyClass: "is_sticky",
      lastScroll: 0,
      showNav: true,
      openNav: false,
      openSearch: false,

      searchQuery: "",
    };
  },

  computed: {
    ...mapGetters([
      "dropdown",
      "isNavStatic",
      "cart",
      "currencies",
      "currency",
      "activeSearchField",
    ]),
    currentRoute() {
      return this.$route.path;
    },
    colorVal() {
      if (this.isNavStatic) {
        return "#5D574F";
      }
      // if(this.isSticky) {
      //   return this.isNavStatic?
      // }
      return this.isSticky ? "#5D574F" : "white";
    },
  },
  created() {
    listCategories().then((res) => {
      this.categories = res;
      this.$nextTick(() => {
        this.categoriesLoaded = true;
      });
    });
    if (this.currentRoute != "/") {
      this.$store.commit("UPDATE_IS_NAV_STATIC", true);
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    openSearchField() {
      this.$router.push("/search?query=" + this.searchQuery);
    },
    cancelSearchField() {
      this.searchQuery = "";
      this.$emit("remount")
      this.$router.back();
    },
    closeMobileNav(val) {
      this.openNav = val;
    },
    openMobileNav() {
      console.log("open cart");
      this.openNav = true;
    },
    handleCurrencyChange(event) {
      this.$store.commit("SET_CURRENT_CURRENCY", event.target.value);
    },
    handleScroll(event) {
      var st = window.pageYOffset;

      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 50) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }

      if (st > this.lastScroll) {
        this.showNav = false;
      } else {
        this.showNav = true;
      }

      this.lastScroll = st <= 0 ? 0 : st;
    },
    goToCategory(slug) {
      // this.closeCart()
      this.$emit("remount");
      this.$router.push(`/categories/${slug}`).catch(() => {});
    },
  },
  components: {
    Menu,
    Magnify,
    WindowClose,
    CartOutline,
    MobileNavigation,
    CheckboxBlankCircle,
  },
  watch: {
    searchQuery(val) {
      this.$router.replace({ path: "/search", query: { query: val } });
    },
    isNavStatic(val) {
      // if(val) {
      //   this.isSticky = true
      // }
    },
    currentRoute(route) {
      this.closeMobileNav();
      if (route == "/") {
        this.$store.commit("UPDATE_IS_NAV_STATIC", false);
      } else {
        this.$store.commit("UPDATE_IS_NAV_STATIC", true);
      }
      if (route == "/search") {
        this.$nextTick(() => {
          this.$refs.searchField.focus();
        });
      }
    },
  },
};
</script>

<style scoped>
.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

nav {
  width: 100%;
}

.navbar {
  width: 100%;
  color: white;
  padding-top: 16px;
  padding-bottom: 16px;
  transition: all 0.2s ease-in-out;
}

.navborder {
  border-bottom: 1px solid #d7cec2;
}
ul li {
  list-style: none;
  display: inline-block;
}

ul li:hover {
  color: #ff6600;
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
}
.logo img {
  width: 170px;
  transition: all 0.2s ease-in-out;
}

ul li:not(last-child) {
  margin-right: 40px;
}

ul {
  margin-right: 80px;
}

.social-icons img {
  width: 25px;
}

.navbar a {
  color: white;
}

a {
  text-transform: uppercase;
  font-size: 16px;
}

.is_sticky {
  position: fixed;
  /* padding-top: 16px; */
  background: #d7cec2;
  color: black !important;
  transition: all 0.2s ease-in-out;
}

.is_sticky .navbar {
  padding-bottom: 0px !important;
  padding-top: 0;
  box-shadow: 2px 3px 9px -2px rgba(0, 0, 0, 0.2);
}

.is_sticky .social-icons img {
  width: 23px;
  filter: invert(70%);
}

.is_sticky .logo img {
  /* height: ; */
  /* filter: invert(); */
}

/* .is_sticky img{
    width: 100px;
    
} */

.is_sticky > .navbar a {
  color: black !important;
}

.nav-link {
  position: relative;
}

.drop-down {
  top: 30px;
  position: absolute;
  left: -300px;
  /* min-height: 150px; */
  /* min-width: 300px; */
  background: #d7cec2;
  color: #403d3a;
  border-radius: 4px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  padding: 15px 20px;
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.2s;
}

.drop-cat-items {
  display: flex;
  justify-content: space-evenly;
}

.drop-cat:not(:last-child) {
  margin-right: 25px;
}

.nav-link:hover .drop-down {
  opacity: 1;
  visibility: visible;
}

.drop-cat h5 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
}

.circle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: blanchedalmond;
  overflow: hidden;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.mobile-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  /* background: #d7cec2; */
  padding-left: 10px;
  padding-right: 10px;
  display: none;
}

.mobile-nav .menu {
  display: flex;
  align-items: center;
}

.mobile-nav span {
  font-size: 22px;
  margin-left: 8px;
  color: white;
}

.mobile-nav-alt span {
  color: #5d574f;
}

.toggle-colors span {
  color: #5d574f;
}

.toggle-colors a {
  color: #403d3a !important;
}

.cart-section span:first-child {
  margin-right: 4px;
}

.currency-select {
  /* padding: 2px 8px; */
  outline: none;
  /* border: 1px solid #D7CEC2; */
  background-color: transparent;
  color: white;
  border: none;
  font-size: 16px;
}

.currency-select option {
  color: black;
}

.is_sticky select {
  color: black !important;
}
/* 
@media(max-width: 1400px){
  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  ul li:not(last-child) {
    margin-right: 36px;
  }
} */

.nav-cart {
  position: relative;
}

.nav-cart-indicator {
  position: absolute;
  z-index: 9;
  right: -10px;
  top: -8px;
  transform: scale(0.6);
}

.search-wrapper {
  background: white;
  width: 100%;
  height: 100%;
  padding: 20px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.search-wrapper input {
  padding: 10px 8px;
  border: none;
  font-size: 22px;
  outline: none;
  width: 90%;
}

.search-wrapper > .search-cancel {
  color: black;
  cursor: pointer;
  border: 1px solid black;
  padding: 8px 10px;
}

@media (max-width: 1024px) {
  .navbar {
    display: none !important;
  }

  .mobile-nav {
    display: flex;
  }

  .nav-cart-indicator {
    position: absolute;
    z-index: 9;
    right: -2px;
    top: 5px;
    transform: scale(0.6);
  }

  .search-wrapper input {
    padding: 10px 8px;
    border: none;
    font-size: 16px;
    outline: none;
    width: 75vw;
  }
}
</style>
