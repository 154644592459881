<template>
  <div class="breadcrumb">
    <router-link to="/">

    <span>Home </span> 
    </router-link>
    <span v-if="noslug">/</span>
    <router-link :to="path">
      <span> {{ breadcrumblink }}</span>
    </router-link>
    <!-- <span v-if="$store.getters.getBreadcrumb"  light :items="$store.getters.getBreadcrumb"></span> -->
  </div>
</template>

<script>

export default {
  data() {
    return {
      breadcrumblink: null,
      noslug: false,
      path: ''
    };
  },
  created() {
    if (this.$route.params.slug) {
      this.breadcrumblink = this.$route.params.slug;
      this.path = this.$route.path
      this.noslug = true;
    } else {
      this.breadcrumblink = this.$route.path.split('/').join(" / ")
    }
  },
};
</script>

<style scoped>
.breadcrumb span {
  font-size: 10px;
  letter-spacing: 2px;
  color: #6d6767;
  text-transform: uppercase;
}
</style>
