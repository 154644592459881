<template>
  <div v-if="!loading" class="home-slider mt-md-5 mb-md-4 pb-3">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center mb-md-4 mb-3">
          <h2>NEW ARRIVALS</h2>
        </div>
      </div>
    </div>
    <flickity
      ref="flickity"
      :options="flickityOptions"
      v-if="!!products.length && sliderReady"
    >
      <div
        v-for="product in productsLocal"
        :key="product.id"
        class="carousel-cell"
      >
        <div class="carousel-img">
          <!-- <img @click="$router.push('/about')" :src="product.images[0]" width="100%" alt="" /> -->
          <img :src="product.images[0]" width="100%" alt="" />
        </div>
        <div class="pt-2 cursor-pointer">
          <div style="color: #56524d; font-size: 16px">{{ product.name }}</div>
          <div style="color: #6b6761; font-size: 14px; margin-top: 3px">
            {{ product.price[0] | ex }}
          </div>
        </div>
      </div>
      <!-- <div class="carousel-cell">
        <div class="carousel-img">
        <img src="@/assets/homeslider/slide2.jpg" width="100%" alt="" />
        </div>
          <div class="pt-2">
            <div style="color: #56524d;">Product Name</div>
            <div style="color:#6b6761;font-size:14px; margin-top:3px;">Rs 200</div>
          </div>            
      </div>    
      <div class="carousel-cell">
        <div class="carousel-img">
        <img src="@/assets/homeslider/slide5.jpg" width="100%" alt="" />
        </div>
          <div class="pt-2">
            <div style="color: #56524d;">Product Name</div>
            <div style="color:#6b6761;font-size:14px; margin-top:3px;">Rs 200</div>
          </div>            
      </div> -->
    </flickity>

    <!-- <div class="mt-2 mb-2 text-center">
      <button @click="$router.push('/products')" class="btn-default">
        View More
      </button>
    </div> -->
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import { mapGetters } from "vuex";
import { listProducts } from "./../api/products";

export default {
  components: {
    Flickity,
  },
  data() {
    return {
      productsLocal: [],
      categoryProducts: [],
      flickityOptions: {
        initialIndex: 2,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
        draggable: true,
        staticClick: true,
        // any options from Flickity can be used
      },
      sliderReady: false,
    };
  },
  computed: {
    ...mapGetters(["products", "loading"]),
    newArrivals() {
      return !!this.products.length
        ? [...this.products].reverse().slice(4, 10)
        : [];
    },
  },
  mounted() {
    listProducts({ field: "collection_slugs", value: "new-arrivals" }).then(
      (res) => {
        console.log("new arrivals loaded...");
        this.productsLocal = [...res];
        setTimeout(() => {
          this.$emit("loaded");
          this.sliderReady = true;
          this.handleItemClick();
        }, 100);
      }
    );
    // .dispatch("fetchProducts", { field: "collection_slugs", value: "new-arrivals" })

    // this.$store
    // .dispatch("fetchProducts", { field: "collection_slugs", value: "new-arrivals" })
    // .dispatch("fetchProducts")
    // .then(() => {
    //   this.$nextTick(res => {
    //     this.productsLocal = Object.assign({}, this.products)
    //     this.$emit("loaded")
    //     this.handleItemClick()
    //   });
    // });
  },
  methods: {
    // handleFlickityReadyState() {
    //   console.log("ready")
    // },
    goToProduct(slug) {
      if (this.$device == "desktop") {
        return;
      }
      this.$emit("remount");
      this.$router.push(`/products/${slug}`).catch(() => {});
    },
    handleItemClick() {
      setTimeout(() => {
        let flkty = this.$refs.flickity;
        flkty.on("staticClick", (event, pointer, cellElement, cellIndex) => {
          let productSlug = this.productsLocal[cellIndex].slug;
          this.$router.push("/products/" + productSlug).catch(() => {});
        });
      }, 100);
    },
  },

  // watch:{
  //   products(val){
  //     if(!!val.length){
  //       console.log('ola')
  //       this.categoryProducts = this.$store.getters.categoryProducts("kurta");
  //     }
  //   }
  // },
};
</script>

<style scoped>
.carousel-cell {
  width: 420px;
  height: 640px;
  margin-right: 10px;
  /* background-color: #D7CEC2; */
  border-radius: 5px;
  counter-increment: gallery-cell;
}

.carousel-img {
  width: 100%;
  height: 551px;
}

.carousel-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-default {
  border: 1px solid #626262;
  outline: none;
  padding: 10px 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: transparent;
}

@media (max-width: 1366px) {
  .carousel-cell {
    width: 320px;
    height: 540px;
    margin-right: 10px;
    /* background-color: #D7CEC2; */
    border-radius: 5px;
    counter-increment: gallery-cell;
  }

  .carousel-img {
    width: 100%;
    height: 451px;
  }

  .carousel-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .home-slider {
    margin-top: 40px;
  }

  .carousel-cell {
    width: 250px;
    height: 420px;
    margin-right: 10px;
    /* background-color: #d7cec2; */
    border-radius: 5px;
    counter-increment: gallery-cell;
  }

  .carousel-img {
    width: 100%;
    height: 350px;
  }

  .carousel-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* .carousel-cell img {
  height: 320px;
  object-fit: cover;
} */
}

/* @media(max-width: 1024px){
  .carousel-cell {
  width: 400px;
  height: 550px;

  }
  .carousel-img{
  width: 100%;
  height: 490px;
}
} */
</style>
