<template>
  <div id="categories"  v-if="!loading" class="collection mt-4 mb-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 text-center mb-3">
          <h2>CATEGORIES</h2>
        </div>
      </div>
      <div class="row no-gutters">
        <div
          @click="goToCategory(collection.slug)"
          class="col-md-4 col-6 collection-col"
          v-for="collection in collections"
          :key="collection.id"
        >
          <div
            class="collection-card"
            :style="{
              backgroundImage: `url(${
                !!collection.image
                  ? collection.image
                  : require('@/assets/collections/collection1.jpg')
              })`,
            }"
          >
            <div class="price-box">
              <!-- <h3>{{ collection.name }}</h3> -->
              <h3>{{ collection.name }}</h3>
              <!-- <router-link :to="`/categories/${collection.slug}`" custom>
                                <button class="shop-btn">Shop Now</button>                            
                            </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listCategories } from "@/api/categories";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      collections: [],
    };
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  created() {
    listCategories(true).then((res) => {
      this.collections = res.splice(0, 6);
      this.$nextTick(() => this.$emit("loaded"));
    });
  },
  methods: {
    goToCategory(slug) {
      this.$router.push("/categories/" + slug);
    },
  },
};
</script>

<style scoped>
.collection {
  padding-left: 7%;
  padding-right: 7%;
}

.collection-card {
  position: relative;
  height: 650px;
  background: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 15px;
}

.collection-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.158);
  position: absolute;
  top: 0;
}

.collection-card::before {
  content: "";
  width: 96%;
  height: 96%;
  border: 2px solid white;
  position: absolute;
  top: 2%;
  left: 2%;
  z-index: 1;
  /* right: 10px;
    bottom: 10px; */
}

.collection-card h3 {
  color: white;
  font-size: 26px;
}

.price-box {
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translate(-50%);
  width: 200px;
  text-align: center;
  z-index: 1;
}

.price-box h3 {
  font-weight: normal;
}

.shop-btn {
  border: 1px solid #626262;
  outline: none;
  padding: 10px 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: black;
  color: white;
  cursor: pointer;
}

.collection-col {
  padding-right: 6px;
  padding-left: 6px;
}

.collection-col:nth-child(3n) {
  padding-right: 0;
}

.collection-col:first-child,
.collection-col:nth-child(4n) {
  padding-left: 0;
}

@media (max-width: 1400px) {
  .collection-card {
    height: 480px;
  }
}

@media (max-width: 1024px) {
  .collection {
    padding-left: 2%;
    padding-right: 2%;
  }
  .collection-card {
    height: 420px;
  }

  .collection-col {
    padding-right: 4px;
    padding-left: 4px;
  }
}

@media (max-width: 450px) {
  .collection-card {
    height: 230px;
  }

  .collection {
    padding-left: 0;
    padding-right: 0;
  }

  .collection-card h3 {
    font-size: 14px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }

  /* .collection-col {
    padding: 0;
  }
 */

  .shop-btn {
    padding: 8px 15px;
    font-size: 10px;
  }
}

@media (max-width: 450px) {
  .collection-col:nth-child(odd) {
    padding-right: 3px;
  }

  .collection-col:nth-child(even) {
    padding-left: 3px;
  }
}
</style>
