<template>
  <div class="shipping-page" style="display: flex;">
      <div class="empty-cart d-flex justify-content-center flex-fill align-items-center" v-if="!cart.length">
      <div class="text-center mb-5">
        <a href="#" title="Image from freeiconspng.com"><img src="https://www.freeiconspng.com/uploads/basket-cart-icon-27.png" width="50" alt="basket cart icon" /></a>
         <h1>YOUR CART IS EMPTY</h1>

        <router-link to='/' class="d-block mt-3"> <u>Return to Shopping</u> </router-link>
      </div>
    </div>
    <div v-else class="container-fluid flex-fill">
      <div class="row" style="height: 100%">
        <div class="col-md-7 pl-100 order-md-1 order-2">
          <div class="left-side">
            <div class="steps">
              <span
                >Home > Address > <span class="active"> Shipping </span></span
              >
            </div>
            <h1>Shipping</h1>
            <div class="review-wrapper py-3">
              <div class="review-card">
                <div class="d-flex flex-wrap" style="flex: 1">
                  <div class="review-name">
                    Contact
                  </div>
                  <div class="review-text">
                    {{ personInfo.email }}
                  </div>
                </div>
                <div class="review-actions">
                  <span @click="changePersonInfo()">
                    change
                  </span>
                </div>
              </div>
              <hr class="my-3" color="#f1ede9" style="border-bottom:none;" />
              <div class="review-card">
                <div class="d-flex flex-wrap flex-fill">
                  <div class="review-name">
                    Ship to
                  </div>

                <div class="review-text">
                  {{ personInfo.address }},
                  <span v-if="personInfo.optionalAddress"
                    >{{ personInfo.optionalAddress }},</span
                  >
                  {{ personInfo.city }}, {{ personInfo.state }},
                  {{ personInfo.pinCode }}
                </div>
                </div>
                <div class="review-actions">
                  <span @click="changePersonInfo()">
                    change
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4 no-gutters">
            <div class="col-6 pr-1">
              <router-link to="/address">
                <button @click="$router.back()" class="btn-default btn-outline">
                  Go Back
                </button>
              </router-link>
            </div>
            <div class="col-6 pl-1">
              <button @click="checkout()" class="btn-default">
                Make Payment
              </button>
            </div>
          </div>
        </div>


       <OrderSummary :hasDeliveryCharge="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { addOrder } from "./../api/orders";
import { incrementByOne, metaRef } from './../services/database'
import { makeOnlinePayment, verifyPayment } from "./../services/cloudFunctions";
// import shortid from "shortid";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import OrderSummary from '../components/OrderSummary.vue'


export default {
  metaInfo: {
    script: [
      {
        src: "https://checkout.razorpay.com/v1/checkout.js",
        async: true,
        defer: true,
      },
    ],
  },
  data: () => ({
    modal: false,
    showOrder: false,
    paymentFailed: false   
  }),
  computed: {
    ...mapGetters([
      "cart",
      "cartTotal",
      "personInfo",
      "currency",
      "currencyObj",
      "showPreloader",
      "deliveryCharge",
      "grandTotal"
    ]),
   
    orderSummaryText() {
      return this.showOrder ? "Hide Order Summary" : "Show Order Summary";
    },
  },

  // watch:{
  //   cartTotal: () =>{
  //      if(this.cartTotal < 15000){
  //       console.log('less')
  //     } else {
  //       console.log('more')
  //     }
  //   }
  // },
  // mounted(){
  //   if (this.cartTotal <= 15000){
  //     console.log('5000')
  //     this.$store.commit("SET_DELIVERY_CHARGE", 5000)
  //   } else {
  //     console.log('8000')
  //     this.$store.commit("SET_DELIVERY_CHARGE", 8000)
  //   }
  // },
  methods: {
    async checkout() {

      this.$store.commit("SET_SHOW_PRELOADER", true);

      let res_3 = {};

      const orderCountDocRef = metaRef.doc("orderCount")
      await orderCountDocRef.update({ lastOid: incrementByOne })
      const oidSnap = await orderCountDocRef.get()
      const oid = oidSnap.data().lastOid

      let order = {
        oid: 'HJ-'+oid,
        items: this.cart,
        subtotal: this.cartTotal,
        deliveryCharge: this.deliveryCharge, 
        total: this.grandTotal,
        personInfo: this.personInfo,
        currency: this.currency,
        currencyObj: this.currencyObj,
        timestamp: Date.now()
      };

      try {
        console.log("creating order...");

        let res_1 = await makeOnlinePayment(order); // createOnlinePayment

        console.log("Order created. Making payment ...")

        let res_2 = await this.payOnline(res_1.data);

        console.log(res_2, "payment complete. Verifying...");

        res_3 = await verifyPayment({
          docId: res_1.data.id,
          paymentOrderId: res_1.data.paymentOrderId
        });        

        res_3 = JSON.parse(JSON.stringify(res_3));

        console.log(res_3)

        this.$store.commit("SET_SHOW_PRELOADER", false);

        if (res_3.data.success) {
          this.$router.replace("/checkout-success");
        } else {
          console.log(res_3.data);
        }
      } catch (error) {
        this.$store.commit("SET_SHOW_PRELOADER", false);                  
      }
    },
    toggleOrderSummary() {
      console.log(this.showOrder);
      this.showOrder = !this.showOrder;
    },
    payOnline(data) {
      return new Promise(async (resolve, reject) => {
        var thisRef = this;

        var options = {
          // key: "rzp_test_CfukWjsBEzOtM2",
          key: "rzp_live_UtXDeS3wCs791f",
          currency: data.currency,
          amount: data.total,
          order_id: data.paymentOrderId,
          name: "Complete Payment",
          description: this.cart.length+' Item(s)',
          modal: {
            ondismiss: function() {
              thisRef.$store.commit("SET_SHOW_PRELOADER", false);
              if(thisRef.paymentFailed) {
                thisRef.$router.push("/checkout-failure");                
              }
            },
          },
          config: {
            display: {
              hide: [
                {
                  method: "paylater"
                },
                {
                  method: "wallet"
                },
                {
                  method: "emi"
                },
                {
                  method: "cardless_emi"
                }
              ]
            },
          },
          handler: (res) => {
            this.paymentFailed = false
            resolve(res);
          },
          prefill: {
            name: this.personInfo.firstName + ' ' + this.personInfo.lastName,
            email: this.personInfo.email,
            contact: this.personInfo.phoneNumber,
          },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.on("payment.failed", function(response) {
          thisRef.showModal = false;
          if(!!response.error && !!response.error.code) {
            thisRef.paymentFailed = true
          } else {
            reject(response);
          }      
        });

        paymentObject.open();
      });
    },
    changePersonInfo() {
      this.$router.push("/address");
    },
  },
  components: {
    ChevronDown,
    ChevronUp,
    OrderSummary
  }  
};
</script>

<style scoped>
.shipping-page {
  display: flex;
  min-height: 100vh;
}

.shipping-page h1 {
  font-size: 30px;
  font-weight: normal;
}

.pl-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.steps {
  margin-top: 20px;
  font-size: 10px;
  text-transform: uppercase;
}

.shop-cart {
  /* background: rgb(241, 241, 241); */
  background-color: #f1ede9;
  /* height: 100vh; */

  height: 100%;
}

.item-cart-list {
  padding: 30px 100px 20px 30px;
}

.item-card {
  height: 100px;
  /* background: rgb(248, 246, 246); */
  display: flex;
  margin-top: 10px;
  border: 1px solid #ccc;
}

.item-image,
.item-price {
  display: inline-block;
}

.item-image {
  height: 100%;
  width: 120px;
  background: #eee;
  overflow: hidden;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.line {
  margin: 20px 0px;
  height: 1px;
  border: 0;
  background-color: #ccc;
}

.item-price {
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

.shipping,
.subtotal {
  font-size: 14px;
}

.total {
  font-weight: bold;
  font-size: 20px;
  font-weight: normal;
}

.item-detail {
  font-size: 10px;
}

.active {
  font-weight: bold;
}

.review-wrapper {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.review-card:not(:last-child) {
  margin-bottom: 10px;
}

.review-card {
  display: flex;
  font-size: 14px;
}

.review-name {
  flex: 0 1 5em;
}

.review-text {
  flex: 5;
  color: black;
}

.review-actions {
  padding-left: 10px;
}

@media (max-width: 786px) {
  .pl-100 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .item-cart-list {
    padding: 30px 15px;
  }
  .shipping-page {
    min-height: auto;
  }
  .review-wrapper {
    margin-bottom: 40px;
    
  }

  .review-card{
    display: flex;
  }

  .review-name{
    
    flex:0 1 5em;

  }

  .review-text{
    width: 100%;
    flex: 0 1 auto;
    margin-top: 10px;
  }

  .shop-cart {
    background-color: #f1ede9;
    height: auto;
  }
}
</style>
