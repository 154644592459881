<template>
  <div ref="side_wrapper">
    <transition name="slide">
      <div class="side-wrapper" v-if="isPanelOpen">
        <div class="d-flex">
          <div class="left" @click="closeCart()"></div>
          <div class="side-cart">
            <!-- ITEM LIST IN CART -->
            <div>
              <div class="cart-header">
                <h3>Cart</h3>
                <div @click="closeCart()">
                  <WindowClose />
                </div>
              </div>
              <div class="items" v-if="!!cart.length">
                <div class="row">
                  <div
                    class="col-12"
                    v-for="(item, index) in cart"
                    :key="item.id + '-' + index"
                  >
                    <div class="item-card">
                      <div class="item-img">
                        <img :src="item.images[0]" alt="" srcset="" />
                      </div>
                      <div class="item-description">
                        <h4 class="m-0 mb-1 item-name">{{ item.name }}</h4>
                        <template v-if="item.size.includes('CUSTOM')">
                          <div class="mb-1 price">
                            <span>{{ item.price | ex }}</span>
                          </div>
                          <div class="d-flex align-items-center">
                            <div style="font-size:13px;display:inline-block;">
                              Size: <span>{{ item.size.split(" ")[0] }}</span>
                              <br />
                              {{ item.size.split(" ")[1] }}
                            </div>
                            <div class="line"></div>
                            <div style="font-size:13px;display:inline-block;">
                              Qty: <span>{{ item.quantity }}</span>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="mb-1 price">
                            <span>{{ item.price | ex }}</span>
                          </div>
                          <div style="font-size:13px;display:inline-block;">
                            Size: <span>{{ item.size }}</span>
                          </div>
                          &nbsp;|&nbsp;
                          <div style="font-size:13px;display:inline-block;">
                            Qty: <span>{{ item.quantity }}</span>
                          </div>
                        </template>

                        <div class="actions">
                          <button @click="dec(item.id, item.size)">-</button>
                          &nbsp;
                          <span>{{ item.quantity }}</span>
                          &nbsp;
                          <button @click="inc(item.id, item.size)">+</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center" v-else>Your cart is Empty</div>
            </div>
            <!-- CHECKOUT BUTTON  -->
            <div v-if="!!cartTotal" class="side-card-action-wrapper">
              <div
                class="d-flex justify-content-between mt-2"
                v-if="!!cart.length"
              >
                <span>Subtotal:</span>
                <span> {{ cartTotal | ex }} </span>
              </div>
              <div class="pt-3" style="font-size:13px;">
                Shipping and taxes calculated at checkout.
              </div>
              <div class="side-wrapper-checkout">
                <button @click="$router.push('/address')" class="btn-default">
                  CHECKOUT
                </button>
              </div>
            </div>

            <!-- CHECKOUT BUTTON  -->
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WindowClose from "vue-material-design-icons/WindowClose.vue";

export default {
  props: {
    isPanelOpen: {
      default: false,
    },
  },
  computed: {
    ...mapGetters(["cart", "cartTotal"]),
    currentRoute() {
      return this.$route.path;
    },
  },
  methods: {
    closeCart() {
      this.$store.commit("ACTIVE_CART", false);
    },
    dec(id, size) {
      this.$store.dispatch("quantityCartAction", {
        id: id,
        action: "dec",
        productSize: size,
      });
    },
    inc(id, size) {
      this.$store.dispatch("quantityCartAction", {
        id: id,
        action: "inc",
        productSize: size,
      });
    },
  },
  components: {
    WindowClose,
  },
  watch: {
    cartTotal(val) {
      if (val == 0) {
        this.$store.commit("ACTIVE_CART", false);
      }
    },
    currentRoute(val) {
      if (val) {
        this.$store.commit("ACTIVE_CART", false);
      }
    },
  },
  // mounted(){
  //  var wrapper = this.$refs.side_wrapper
  //  wrapper.addEventListener('touchmove', function(e) {
  //    e.preventDefault()
  //  }, false)
  // }
};
</script>

<style scoped>
h3 {
  font-weight: normal;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}

.side-cart {
  position: absolute;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 100%;
  width: 40vw;
  right: 0;
  padding: 3rem 20px 2rem 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.side-cart {
  padding: 10px 15px;
  
}

.item-card {
  border: 1px solid rgb(223, 223, 223);
  min-height: 120px;
  max-height: 124px;
  display: flex;
  margin-bottom: 10px;
}

.item-img {
  flex: 1;
  background: #fff;
}

.item-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}

.item-description {
  flex: 5;
  padding: 10px;
}

.item-description h4 {
  font-size: 15px;
}

.item-description .price {
  font-size: 14px;
}

.item-description > .item-name {
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
   white-space: nowrap;
   width: 88%;
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions button {
  padding: 3px 7px;
  border: 1px solid black;
  outline: none;
  border: none;
  margin-top: 15px;
}

.left {
  flex: 2;
  height: 100vh;
  /* background-color: teal; */
}

.side-wrapper {
  /* position: relative; */
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  padding-right: 15px;
  overflow: hidden;
  overscroll-behavior: contain;
}

.side-wrapper-checkout {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  /* position: fixed;
  bottom: 0;  
  width: 69%;
  display: flex;
  justify-content: center; */
  /* padding-bottom: 15px; */
}

.items {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none !important;
  -webkit-overflow-scrolling: touch !important;
}

.side-card-action-wrapper {
  border-top: 1px solid #D7CEC2;
  position: absolute;
  flex-grow: 1;
  bottom: 0;
  background: white;
  padding: 0px 10px;
  padding-top: 15px;
  right: 0;
  left: 0;
}

.line {
  width: 1px;
  background: #6b6761;
  margin: 0 10px;
  align-self: stretch;
}

@media (max-width: 768px) {
  .side-wrapper {
    padding-right: 0;
  }

  .left {
    flex: 1;
  }

  .side-cart {
    flex: 11;
    width: 90vw;
  }

  .item-card {
    min-height: 120px;
    max-height: 140px;
    display: flex;
  }
  .item-img {
    /* flex: 1; */
    background: #eee;
  }

  .item-description{
    /* flex: 0; */
  }

  .item-img img {
    width: 100px !important;
    height: 100%;
    width: 100px !important;
    object-fit: cover;
    object-position: top;
  }
}
</style>
