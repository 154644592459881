<template>
  <div
    class="success-order d-flex flex-column justify-content-center align-items-center"
  >
    <div class="px-3">
      <!-- <img :src="require('@/assets/sucess.gif')" alt="" width="80px" /> -->
      
      <div class="text-center mt-3">
        <span class="animate__animated animate__zoomIn">
          <CheckCircle :size="54" />
        </span>
      </div>

      <h1 style="font-size:26px;" class="text-center">
        Order Failed
      </h1>
      <span class="text-center d-block">
        If any money has been deducted, it will be refunded back to your account in 5 - 7 days

        <p>
          For more support,contact <strong>info.harshitajain@gmail.com</strong>
        </p>

        <p>
          <button class="btn-default btn-outline mt-3 mb-2" @click="$router.replace('/')">BACK TO HOME</button>          
        </p>        
      </span>
      <!-- {{ personInfo }} -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CheckCircle from 'vue-material-design-icons/CloseCircle.vue'

export default {
  computed: {
    ...mapGetters(["cart", "cartTotal", "personInfo"]),
  },
  components: {
    CheckCircle
  },
  destroyed() {
    this.$store.commit("EMPTY_CART")    
  }
};
</script>

<style scoped>
.success-order {
  min-height: 70vh;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f1ede9;
}

.shipping-info {
  margin-top: 10px;
  border: 1px solid #dddd;
  padding: 0px 10px;
  margin-bottom: 20px;
}
</style>
