var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNav),expression:"showNav"}],staticClass:"navigation",class:[
    _vm.isSticky ? _vm.stickyClass : '',
    _vm.currentRoute != '/' ? 'navborder' : '',
    _vm.isNavStatic ? 'toggle-colors' : '' ]},[(_vm.activeSearchField)?_c('div',{staticClass:"search-wrapper"},[_c('Magnify',{attrs:{"fillColor":_vm.colorVal}}),_c('input',{ref:"searchField",attrs:{"type":"text","placeholder":"Search Product"},domProps:{"value":_vm.searchQuery},on:{"input":function (evt) { return (_vm.searchQuery = evt.target.value); }}}),_c('WindowClose',{on:{"click":function($event){return _vm.cancelSearchField()}}})],1):_c('nav',{staticClass:"navbar d-none d-lg-block"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"logo ml-5 pl-5"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.isSticky || _vm.isNavStatic)?_c('img',{staticClass:"mt-2 ml-2",attrs:{"width":"160","src":require('@/assets/logo/logo-dark.png'),"alt":""}}):_c('img',{staticClass:"mt-2 ml-2",attrs:{"width":"160","src":require('@/assets/logo/harshita-jain-logo-white.png'),"alt":""}})])],1),_c('div',[_c('ul',{staticClass:"ml-5"},[_c('li',{staticClass:"nav-link"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home ")])],1),_c('li',{staticClass:"nav-link"},[_c('router-link',{attrs:{"to":"/collections/women"},nativeOn:{"click":function($event){return _vm.$emit('remount')}}},[_vm._v(" Women ")]),_c('div',{staticClass:"drop-down"},[_c('div',{staticClass:"drop-cat-items"},_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,staticClass:"drop-cat"},[_c('div',{staticClass:"circle",on:{"click":function($event){return _vm.goToCategory(category.slug)}}},[_c('img',{attrs:{"src":!!category.image
                          ? category.image
                          : require('@/assets/homeslider/slide2.jpg'),"alt":""}})]),_c('h5',{staticClass:"m-0 mt-2",on:{"click":function($event){return _vm.goToCategory(category.slug)}}},[_vm._v(" "+_vm._s(category.name)+" ")])])}),0)])],1),_c('li',{staticClass:"nav-link"},[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("About")])],1),_c('li',{staticClass:"nav-link"},[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("Contact")])],1),_c('li',[_c('select',{staticClass:"currency-select",style:({ color: _vm.isNavStatic ? '#403d3a' : '' }),attrs:{"name":"","id":""},domProps:{"value":_vm.currency},on:{"change":function($event){return _vm.handleCurrencyChange($event)}}},_vm._l((_vm.currencies),function(currency){return _c('option',{key:currency.name,domProps:{"value":currency.name}},[_vm._v(" "+_vm._s(currency.symbol)+" "+_vm._s(currency.name)+" ")])}),0)]),_c('li',[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.openSearchField}},[_c('Magnify',{attrs:{"fillColor":_vm.colorVal}})],1)]),_c('li',[_c('div',{staticClass:"d-inline-block nav-cart",on:{"click":function($event){return _vm.$store.commit('ACTIVE_CART', true)}}},[_c('CartOutline',{attrs:{"fillColor":_vm.colorVal}}),(!!_vm.cart.length)?_c('div',{staticClass:"d-inline-block nav-cart-indicator"},[_c('CheckboxBlankCircle',{attrs:{"fillColor":_vm.colorVal}})],1):_vm._e()],1)])])])])]),(!_vm.activeSearchField)?_c('nav',[_c('div',{staticClass:"mobile-nav",class:[
        _vm.isSticky ? 'mobile-nav-alt' : '',
        _vm.isNavStatic ? 'toggle-colors' : '' ]},[_c('div',{staticClass:"menu"},[_c('Menu',{attrs:{"size":26,"fillColor":_vm.colorVal},on:{"click":function($event){return _vm.openMobileNav()}}}),_vm._v("   "),_c('router-link',{attrs:{"to":"/","custom":""}},[(_vm.isSticky || _vm.isNavStatic)?_c('img',{staticClass:"mt-2 ml-2",attrs:{"width":"160","src":require('@/assets/logo/logo-dark.png'),"alt":""}}):_c('img',{staticClass:"mt-2 ml-2",attrs:{"width":"160","src":require('@/assets/logo/harshita-jain-logo-white.png'),"alt":""}})])],1),_c('div',{staticClass:"cart-section"},[_c('Magnify',{attrs:{"fillColor":_vm.colorVal},on:{"click":_vm.openSearchField}}),_c('div',{staticClass:"d-inline-block",on:{"click":function($event){return _vm.$store.commit('ACTIVE_CART', true)}}},[_c('CartOutline',{attrs:{"fillColor":_vm.colorVal}}),(!!_vm.cart.length)?_c('div',{staticClass:"d-inline-block",staticStyle:{"position":"absolute","z-index":"9","right":"-2px","top":"5px","transform":"scale(0.6)"}},[_c('CheckboxBlankCircle',{attrs:{"fillColor":_vm.colorVal}})],1):_vm._e()],1)],1)])]):_vm._e(),_c('MobileNavigation',{attrs:{"isPanelOpen":_vm.openNav},on:{"remount":function($event){return _vm.$emit('remount')},"closeMobileNav":_vm.closeMobileNav}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }