var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mobilenav_wrapper"},[_c('transition',{attrs:{"name":"slide"}},[(_vm.isPanelOpen)?_c('div',{staticClass:"side-wrapper"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"side-nav"},[_c('div',[_c('div',{staticClass:"cart-header mt-3"},[_c('h3',[_c('strong',[_vm._v("MENU")])]),_c('div',{staticClass:"pr-3",on:{"click":function($event){return _vm.closeCart()}}},[_c('WindowClose')],1)]),_c('div',{staticClass:"mobile-menu"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v(" HOME ")])],1),_c('li',{on:{"click":function($event){return _vm.openMobDrop(1)}}},[_c('div',{staticClass:"d-flex justify-content-between link-color"},[_vm._v(" Women "),_c('span',[_c('ChevronDown')],1)]),(_vm.dropIndex == 1 && _vm.categoriesLoaded)?_c('div',{staticClass:"mobile-drop mt-3"},[_c('div',{staticClass:"flickity-wrapper"},[_c('flickity',{ref:"flickity",attrs:{"options":_vm.flickityCollectionOptions}},_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,staticClass:"carousel-cell",on:{"click":function($event){return _vm.goToCategory(category.slug)}}},[_c('div',{staticClass:"carousel-img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                                !!category.image
                                  ? category.image
                                  : require('@/assets/homeslider/slide2.jpg')
                              ),expression:"\n                                !!category.image\n                                  ? category.image\n                                  : require('@/assets/homeslider/slide2.jpg')\n                              "}],staticClass:"lazy-img",attrs:{"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(category.name))])])}),0),(_vm.showFlickityDirectionIndicator)?_c('div',{attrs:{"id":"flickityDirectionIndicator"},on:{"click":function($event){return _vm.goToNextSlide()}}},[_c('ArrowRight',{attrs:{"size":14}})],1):_vm._e()],1)]):_vm._e()]),_c('li',{on:{"click":function($event){return _vm.openMobDrop(2)}}},[_c('div',{staticClass:"d-flex justify-content-between link-color"},[_vm._v(" COLLECTIONS "),_c('span',[_c('ChevronDown')],1)]),(_vm.dropIndex == 2 && _vm.categoriesLoaded)?_c('div',{staticClass:"mobile-drop mt-3"},[_c('div',{staticClass:"flickity-wrapper"},[_c('flickity',{ref:"flickity1",attrs:{"options":_vm.flickityCollectionOptions}},_vm._l((_vm.collections),function(collection){return _c('div',{key:collection.id,staticClass:"carousel-cell",staticStyle:{"width":"120px"},on:{"click":function($event){return _vm.goToCategory(collection.slug, true)}}},[_c('div',{staticClass:"carousel-img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                          !!collection.url
                                  ? collection.url
                                  : require('@/assets/homeslider/slide2.jpg')
                        ),expression:"\n                          !!collection.url\n                                  ? collection.url\n                                  : require('@/assets/homeslider/slide2.jpg')\n                        "}],staticClass:"lazy-img",attrs:{"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(collection.name))])])}),0),(_vm.showFlickityDirectionIndicator)?_c('div',{attrs:{"id":"flickityDirectionIndicator"},on:{"click":function($event){return _vm.goToNextSlide()}}},[_c('ArrowRight',{attrs:{"size":14}})],1):_vm._e()],1)]):_vm._e()]),_c('li',[_c('router-link',{attrs:{"to":"/about"}},[_vm._v(" About ")])],1),_c('li',[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v(" Contact ")])],1)])])]),_c('div',{staticClass:"currency-wrapper"},[_c('h3',[_vm._v("Change Currency")]),_c('select',{staticClass:"currency-select",attrs:{"name":"","id":""},domProps:{"value":_vm.currency},on:{"change":function($event){return _vm.handleCurrencyChange($event)}}},_vm._l((_vm.currencies),function(currency){return _c('option',{key:currency.name,domProps:{"value":currency.name}},[_vm._v(" "+_vm._s(currency.symbol)+" "+_vm._s(currency.name)+" ")])}),0)])]),_c('div',{staticClass:"right",on:{"click":function($event){return _vm.closeCart()}}})])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }