<template>
  <div class="home-slider mb-5 mt-2">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center mb-4">
          <h4>YOU MAY ALSO LIKE</h4>
        </div>
      </div>
    </div>

    <!-- {{ relatedItems }} -->

    <flickity v-if="showRelatedItems" ref="flickity" :options="flickityOptions">
      <div class="carousel-cell" v-for="item in relatedItems" :key="item.slug">
        <div @click="goToProduct(item.slug)">
          <div class="img-holder">
            <img :src="item.images[0]" width="100%" alt="" />
          </div>
          <div class="related-item-text">
            <h4 class="mb-2">{{ item.name | titleCase }}</h4>
            <p class="m-0">{{ item.price[0] | ex }}</p>
          </div>
        </div>
      </div>
    </flickity>

    <!-- <div class="mt-5 text-center">
        <button class="btn-default">View More</button>
    </div> -->
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import { getRelatedItems } from "./../api/products";

export default {
  components: {
    Flickity,
  },
  data() {
    return {
      relatedItems: [],
      showRelatedItems: false,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        contain: true,
        wrapAround: false,
        autoPlay: false,
        // any options from Flickity can be used
      },
    };
  },
  created() {
    getRelatedItems().then((items) => {
      this.relatedItems = items;
      setTimeout(() => {
        this.showRelatedItems = true;
      }, 500);
    });
  },
  // props:{
  //     relatedItems:{
  //         type: Array,
  //         default() {
  //           return  [
  //               { name: "Green Tunics", img: require("@/assets/collections/collection4.jpg"), slug: "kurta-four",  price:[10000,25000,30000] },
  //               { name: "Yellow Tunics", img: require("@/assets/collections/collection3.jpg"), slug: "kurta-three",  price:[10000,25000,30000] },
  //               { name: "Pink Tunics", img: require("@/assets/collections/collection2.jpg"), slug: "kurta-two",  price:[10000,25000,30000] },
  //               { name: "Yellow Tunics", img: require("@/assets/collections/collection1.jpg"), slug: "kurta-one",  price:[10000,25000,30000] },
  //           ]
  //         }
  //     }
  // },
  methods: {
    goToProduct(slug) {
      // this.$store.commit("SET_DISABLE_LOADING", false)
      this.$emit("remount");
      this.$router.push(`/products/${slug}`).catch(() => {});
    },
  },
};
</script>

<style scoped>
h3 {
  /* font-weight: normal; */
}

.carousel-cell {
  width: 300px;
  height: 450px;
  margin-right: 10px;
  background: white;
  border-radius: 5px;
  counter-increment: gallery-cell;
}

.img-holder {
  height: 360px;
  overflow: hidden;
}

.img-holder img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
}

.related-item-text {
  text-align: left;
}

.related-item-text p {
  font-size: 14px;
}

.related-item-text h4 {
  padding: 0px;
  margin: 10px 0px 5px 0px;
  font-weight: normal;
}

.btn-default {
  border: 1px solid #626262;
  outline: none;
  padding: 10px 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: transparent;
}

@media (max-width: 700px) {
  .carousel-cell {
    width: 200px;
    height: 350px;
  }

  .img-holder {
    height: 260px;
    overflow: hidden;
  }

  .related-item-text h4 {
    /* font-size: 16px; */
    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */
    font-weight: normal;
  }

  .related-item-text p {
    font-size: 14px;
  }
}
</style>
