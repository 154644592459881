<template>
  <div class="banner-one mb-4">
    <img :src="getBannerImg" alt="" srcset="" />
    <div class="categories-cta-wrapper">
        <!-- <a href="#categories">go</a> -->      
        <button @click.prevent="scroll('categories')">VIEW CATEGORIES</button>    
    </div>
  </div>
</template>



<script>
import axios from "axios";
export default {
  data() {
    return {
      banner_url: "",
    };
  },
  async created() {
    let res = await axios.get(
      "https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/home_banner.json"
    );
    this.banner_url = res.data;
  },
  computed: {
    getBannerImg() {
      if (this.$mq == "sm" || this.$mq == "sm_md") {
        return this.banner_url.mobile;
      }
      return this.banner_url.desktop;
    },
  },
  methods:{
    scroll(id) {  
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    }
  }
};
</script>
<style scoped>
.banner-one {
  height: 90vh;
  position: relative;
}

.banner-one img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.categories-cta-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translateY(-50%) ; */
}

.categories-cta-wrapper button {
  background: transparent;
  outline: none;
  /* border: none; */
  border: 2px solid #d7cec2;
  border-right-color: transparent;  
  border-left-color: transparent;    
  padding: 25px 50px;
  /* color: #6b6761; */
  color: #d7cec2;
  font-size: 20px;
  /* font-weight: bold; */
  cursor: pointer;
  letter-spacing: 3px;  
}

@media (max-width: 1024px) {
  .banner-one {
    height: 55vh;
    /* margin-bottom: -10px !important; */
  }
}

@media (max-width: 768px) {
  .banner-one {
    height: 95vh;
    /* margin-bottom: -10px !important; */
  }
  .banner-one img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>