<template>
  <div class="preview-image">
    <div class="container-fluid px-0">
      <div class="row no-gutters">
        <div class="col-12 py-2 d-none d-md-block top-bar">
          <WindowClose @click="closePreview" />
        </div>
        <div class="col-12">
          <pinch-zoom :limitZoom="3">
              <img :src="currentProduct.images[imageIndex]" class="preview-img">
          </pinch-zoom>
        </div>
        <div class="col-12 py-2 text-center d-md-none">
          <WindowClose @click="closePreview" />
        </div>        
      </div>
    </div>
          <!-- <img width="200" class="m-md-2" v-for="(image,index) in currentProduct.images" :src="image" :key="`image-${index}`" alt="">     -->
  </div>
</template>

<script>
import PinchZoom from 'vue-pinch-zoom';
import WindowClose from 'vue-material-design-icons/WindowClose.vue'
import { mapGetters } from 'vuex'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {      
      vm.fromProduct = from.name == "SingleProduct";
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_FULLSCREEN_STATE", false) 
    next()
  },
  data: () => ({
    fromProduct: false,
    slug: null,
    imageIndex: 0,
  }),
  created() {
    this.$store.commit("SET_FULLSCREEN_STATE", true)
  },
  mounted() {
    this.imageIndex = this.$route.query.imgIndex
    if(this.fromProduct) {
      this.$hidePageLoader()
    } else {
      let slugArr = this.$route.path.split('/')   
      this.slug = slugArr[slugArr.length - 2]
      this.$store.dispatch("fetchProduct", this.slug)
      .then(() => {
        console.log("loaded...")
        this.$hidePageLoader()
      })
    }
  },
  computed: {
    ...mapGetters(['currentProduct'])
  },
  methods: {
    closePreview() {      
      this.fromProduct? this.$router.back() : this.$router.replace(`/products/${this.slug}`)
    }
  },
  components: {
    WindowClose,
    'pinch-zoom': PinchZoom    
  }  
}
</script>

<style scoped>

  .top-bar {
    position: fixed;
    display: block;
    width: 100%;
    /* background: rgba(255, 255, 255, 0.616); */
    background: #d7cec2b9;
    padding-left:20px;
    top:0;
    left:0;
    z-index: 2;
  }

  .preview-img {
    max-height: 100vh !important;
  }
</style>