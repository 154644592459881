import { ordersRef } from './../services/database'

export async function addOrder(data) {
    let docId = ordersRef.doc().id
    let order = { ...data, id: docId}
    return ordersRef.doc(docId).set(order).then(() => Promise.resolve(order))
}

// export const getInfluencerProfiles = (influencersList) => {
//     return new Promise((resolve, reject) => {                        
//         let profileUrlList = influencersList.map(user => user.platform.url.split('?')[0])    
//         axios.get('http://localhost:9000/scrape-users', {
//             params: {
//                 profileUrlListStr: profileUrlList.join('|')
//             }
//         })
//         .then(res => {
//             let data = []
//             res.data.userDataList.forEach((item,index) => {
//                 data.push({...getInfluencerData(JSON.parse(item)), uid: influencersList[index].id, updatedAt: Date.now() })
//             })            
//             // data.push({...getInfluencerData(json), uid: influencersList[0].id })            
//             resolve(data)
//         })
//         .catch(e => reject(e))
//     })
// }

// export const getInfluencerData = (json) => {
//     let user = json.graphql.user
//     let userPart = _.pick(user, [
//             'biography', 
//             'edge_follow',
//             'edge_followed_by',
//             'edge_owner_to_timeline_media',
//             'category_name',            
//             'is_private',
//             'full_name'
//         ])

//         let node;
//         userPart.posts = userPart.edge_owner_to_timeline_media.edges.map(edge => { 
//         node = edge.node
//         return {
//             id: node.id,
//             url: node.display_url,
//             commentsCount: node.edge_media_to_comment.count,
//             // location: node.location.name,
//             timestamp: node.taken_at_timestamp,
//             likeCount: node.edge_media_preview_like.count,
//             isVideo: node.is_video
//             }        
//         })
//         userPart['followersCount'] = userPart.edge_followed_by.count
//         userPart['followingCount'] = userPart.edge_follow.count
//         userPart['postsCount'] = userPart.edge_owner_to_timeline_media.count,        
//         delete userPart.edge_owner_to_timeline_media
//         delete userPart.edge_followed_by
//         delete userPart.edge_follow
//         return userPart    
// }

// export const updateInfluencer = (data, operation) => {
//     if(!data.id) { return }      
  
//     switch(operation) {
//       case 'arrayUnion':
//         value = firestore.FieldValue.arrayUnion(value)
//         break
//       case 'arrayRemove':
//         value = firestore.FieldValue.arrayRemove(value)               
//     }
        
//     return influencersRef
//       .doc(data.id)
//       .update({ [data.field]: data.val })      
//   }
