<template>
  <div class="zoom">
    <inner-image-zoom 
      src="https://images.pexels.com/photos/6064302/pexels-photo-6064302.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
      zoomSrc="https://images.pexels.com/photos/6064302/pexels-photo-6064302.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
      zoomType="hover"
      hideHint: false
    />
  </div>
</template>

<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from "vue-inner-image-zoom";
export default {
  components: {
    "inner-image-zoom": InnerImageZoom,
  },
};
</script>

<style scoped>
.zoom {
  /* width: 700px; */

}

.iiz__hint {
    bottom: 10px;
    right: 10px;
    pointer-events: none;
    display: none !important;
}
</style>
