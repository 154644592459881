<template>
  <div ref="mobilenav_wrapper">
    <transition name="slide">
      <div class="side-wrapper" v-if="isPanelOpen">
        <div class="d-flex">
          <div class="side-nav">
            <div>
              <div class="cart-header mt-3">
                <h3><strong>MENU</strong></h3>
                <div @click="closeCart()" class="pr-3">
                  <WindowClose />
                </div>
              </div>
              <div class="mobile-menu">
                <ul>
                  <li>
                    <router-link to="/"> HOME </router-link>
                  </li>
                  <li @click="openMobDrop(1)">
                    <div class="d-flex justify-content-between link-color">
                      Women
                      <span>
                        <ChevronDown />
                      </span>
                    </div>
                    <!-- <transition name="fade"> -->
                    <div
                      class="mobile-drop mt-3"
                      v-if="dropIndex == 1 && categoriesLoaded"
                    >
                      <div class="flickity-wrapper">
                        <flickity ref="flickity" :options="flickityCollectionOptions">
                          <div
                            @click="goToCategory(category.slug)"
                            class="carousel-cell"
                            v-for="category in categories"
                            :key="category.id"
                          >
                            <div class="carousel-img">
                              <img
                                v-lazy="
                                  !!category.image
                                    ? category.image
                                    : require('@/assets/homeslider/slide2.jpg')
                                "
                                alt=""
                                class="lazy-img"
                              />
                            </div>
                            <div class="name">{{ category.name }}</div>
                          </div>
                        </flickity>
                        <div @click="goToNextSlide()" v-if="showFlickityDirectionIndicator" id="flickityDirectionIndicator">
                          <ArrowRight :size="14" />
                        </div>
                      </div>
                    </div>
                    <!-- </transition> -->
                  </li>
                  <li @click="openMobDrop(2)">
                  <div class="d-flex justify-content-between link-color">
                    COLLECTIONS
                    <span>
                      <ChevronDown />
                    </span>
                  </div>
                  <div class="mobile-drop mt-3" v-if="dropIndex == 2 && categoriesLoaded">
                     <div class="flickity-wrapper">
                    <flickity ref="flickity1" :options="flickityCollectionOptions">
                    <div
                      @click="goToCategory(collection.slug, true)"
                      class="carousel-cell"
                      style="width:120px;"
                      v-for="collection in collections"
                            :key="collection.id"
                    >
                      <div class="carousel-img">
                        <img
                          v-lazy="
                            !!collection.url
                                    ? collection.url
                                    : require('@/assets/homeslider/slide2.jpg')
                          "
                          alt=""
                          class="lazy-img"
                        />
                      </div>
                      <div class="name">{{ collection.name }}</div>
                    </div>
                    </flickity>
                      <div @click="goToNextSlide()" v-if="showFlickityDirectionIndicator" id="flickityDirectionIndicator">
                          <ArrowRight :size="14" />
                        </div>
                     </div>
                  </div>
                </li>
                  <!-- <li>
                    <router-link to="/collections/jewellery"> Jewellery </router-link>
                  </li> -->
                  <li>
                    <router-link to="/about"> About </router-link>
                  </li>
                  <li>
                    <router-link to="/contact"> Contact </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="currency-wrapper">
              <h3>Change Currency</h3>
              <select
                @change="handleCurrencyChange($event)"
                name=""
                id=""
                :value="currency"
                class="currency-select"
              >
                <option
                  v-for="currency in currencies"
                  :key="currency.name"
                  :value="currency.name"
                >
                  {{ currency.symbol }} {{ currency.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="right" @click="closeCart()"></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { listCategories } from "@/api/categories";
import { getCollections } from "../api/collection";
import WindowClose from "vue-material-design-icons/WindowClose.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue"
import Flickity from "vue-flickity";

export default {
  data() {
    return {
      dropIndex: 0,
      open: false,
      categories: [],
      collections:[],
      showFlickityDirectionIndicator: true,
      flkty: null,
      flkty1:null,
      categoriesLoaded: false,
      goNextSlideClicked: false,        
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: false,
        autoPlay: false,
        freeScroll: true,
        contain: true,
      },
      flickityCollectionOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: false,
        autoPlay: false,
        freeScroll: true,
        contain: true,        
      }
    };
  },
  props: {
    isPanelOpen: {
      default: false,
    },
  },
  computed: {
    ...mapGetters(["cart", "cartTotal", "currency", "currencies"]),
  },

  methods: {
    closeCart() {
      this.$emit("closeMobileNav", false);
    },
    goToNextSlide() {
      this.goNextSlideClicked = true
      this.$nextTick(() => {
        this.flkty.next()
      })
    },
    goToCategory(slug, isCollection = false) {
      let location = isCollection? 'collections' : 'categories'
      this.closeCart();
      this.$emit("remount");
      this.$router.push(`/${location}/${slug}`).catch(() => {});
    },
    openMobDrop(data) {
      // this.open = !this.open
      if (this.dropIndex == data) {
        if(this.goNextSlideClicked) {
          this.goNextSlideClicked = false
        } else {
          this.dropIndex = 0;  
        }
        this.showFlickityDirectionIndicator = true      
      } else {        
        this.dropIndex = data;
        this.handleItemClick()
      }
    },
    handleCurrencyChange(event) {
      this.$store.commit("SET_CURRENT_CURRENCY", event.target.value);
    },
    handleItemClick() {
      // temporary fix

      const ref = this.dropIndex == 1 ? 'flickity' : 'flickity1'

      setTimeout(() => {
        this.flkty = this.$refs[ref]
        this.flkty.on('change', (index) => {
          this.showFlickityDirectionIndicator = false
        });      
      }, 100)    
    }
  },
  mounted() {
    listCategories().then((res) => {
      this.categories = res;
      this.$nextTick(() => {
        this.categoriesLoaded = true;
      });
    });

    getCollections().then((res) => {
      this.collections = res
    })

    var wrapper = this.$refs.mobilenav_wrapper;
    wrapper.addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
      },
      false
    );
  },
  components: {
    WindowClose,
    ChevronDown,
    Flickity,
    ArrowRight
  },
}
</script>

<style scoped>


  @media(max-width:768px) {
    
    .flickity-button {
      /* display: block !important; */
    }
    .flickity-prev-next-button.previous{
      left: 0px;
    }
    .flickity-prev-next-button.next{
      right: 0px;
    }

    .flickity-wrapper {
      /* background: orange; */
      position: relative;
    }

    #flickityDirectionIndicator {
      position: absolute;
      top:30%;
      right:-10px;
      z-index: 99;
      background: #d7cec28a;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      padding-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

      
}

h3 {
  font-weight: normal;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.side-nav {
  position: relative;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 100vh;
  padding: 3rem 20px 2rem 20px;
  flex: 3;
}

.right {
  flex: 1;
  height: 100vh;
}

.side-nav {
  padding: 10px;
  overflow-y: hidden;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}

li {
  list-style: none;
}

.link-color {
  color: #403d3a;
}

.item-card {
  border: 1px solid rgb(223, 223, 223);
  height: 100px;
  display: flex;
  margin-bottom: 10px;
}

.item-img {
  flex: 22%;
  background: rebeccapurple;
}

.item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.item-description {
  flex: 44%;
  padding: 10px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions button {
  padding: 3px 7px;
  border: 1px solid black;
  outline: none;
  border: none;
  margin-top: 15px;
}

.mobile-menu {
  border-top: 1px solid gray;
  margin-right: 20px;
  padding-top: 20px;
}

.mobile-menu ul {
  margin: 0px;
  padding: 0px;
}

.mobile-menu ul > li {
  text-transform: uppercase;
  padding: 10px 0px;
  font-size: 18px;
}

.mobile-drop {
  /* display: none; */
  transition: all 1s ease-in-out;
  /* opacity: 0; */
}

.drop-item {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: green;
}

.drop-items span {
  font-size: 12px;
}
/* .drop-item:not(:last-child){
    margin-right: 8px;
} */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.carousel-cell {
  min-width: 80px;
  height: 100px;
  /* border-radius: 100%; */
  margin-right: 10px;
  /* background-color:#eee; */
  /* border-radius: 5px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.carousel-img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
}

.carousel-img img {
  /* height: 320px; */
  width: 100%;
  object-fit: contain;
}

.name {
  font-size: 12px;
  color: #969087;
  text-align: center;
  padding-top: 10px;
}

.side-wrapper {
  /* position: relative; */
  width: 100vw;
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.lazy-img[lazy="loaded"] {
  opacity: 0;
  animation-name: fadein;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-timing-function: ease-out;
}
.currency-wrapper {
  margin-top: 30px;
  margin-bottom: 11vh;
}

.currency-wrapper h3 {
  text-transform: uppercase;
  font-size: 12px;
}

.currency-select {
  padding: 8px 25px;
  outline: none;
  border: 1px solid #d7cec2;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
