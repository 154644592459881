<template>
  <div class="home-collection my-4">
    <flickity ref="flickity" :options="flickityOptions">
      <div class="carousel-cell">
        <div class="border"></div>
        <img
          src="https://images.pexels.com/photos/5699257/pexels-photo-5699257.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt=""
        />
      </div>
      <div class="carousel-cell">
        <div class="border"></div>
        <img
          src="https://images.pexels.com/photos/994517/pexels-photo-994517.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
          alt=""
        />
      </div>
      <div class="carousel-cell">
        <div class="border"></div>
        <img
          src="https://images.pexels.com/photos/3875430/pexels-photo-3875430.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt=""
        />
      </div>
    </flickity>
  </div>
</template>

<script>
import Flickity from "vue-flickity";

export default {
  components: {
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 3,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
        // any options from Flickity can be used
      },
    };
  },
};
</script>

<style scoped>

.carousel-cell {
    position: relative;
    width: 90%;
    height: 460px;
    margin-right: 10px;
    background-color: #eee;
    /* border-radius: 5px; */
    counter-increment: gallery-cell;
  }

  .carousel-cell img {
    width: 100%;
    object-fit: cover;
    object-position:top center;
  }


  .border {
    position: absolute;
    border: 4px solid white;
    width: 98%;
    height: 96%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 5px; */
  }


@media (max-width: 768px) {
  .carousel-cell {
    position: relative;
    width: 90%;
    height: 200px;
    margin-right: 10px;
    background-color: #eee;
    /* border-radius: 5px; */
    counter-increment: gallery-cell;
  }

 

  .carousel-cell img {
    width: 100%;
    object-fit: cover;
  }
}
</style>
