<template>
  <div class="search px-1 px-md-5">
    <div class="container-fluid">
      <div class="row">
          <!-- v-for="result in []" -->
          <template v-for="result in $mq !== 'sm'? searchResults.slice(0,9) : searchResults">
            <div
              v-if="!preventDisplay"
              class="col-6 col-md-4 px-2"
              :key="result.id"
            >
              <!-- data-aos="fade-up"
                      data-aos-duration="700"
                      data-aos-delay="300" -->
              <!-- <router-link :to="`/products/${result.slug}`"> -->
                <div @click="$router.replace('/products/'+result.slug)" class="product-card">
                  <div class="product-image">
                    <img
                      v-lazy="result.images[0]"
                      alt=""
                      srcset=""
                      class="lazy-img"
                    />
                  </div>
                  <div class="product-info">
                    <div class="name mt-2 mb-1">{{ result.name }}</div>
                    <span class="price">{{ result.price[0] | ex }}</span>
                  </div>
                  <!-- 
                {{ item.price[0] }} -->
                </div>
              <!-- </router-link> -->
            </div>
          </template>
          <div v-show="itemsLoaded && !preventDisplay" class="col-12 px-2">
            <h3>Categories</h3>
            <hr style="margin-bottom:15px;">            
            <!-- <router-link custom :to="categorySlug(category)" v-for="(category,index) in categories" :key="`category-${index}`"> -->
              <div @click="goToCategory(category)" class="mb-2" v-for="(category,index) in categories" :key="`category-${index}`">
                <span class="cursor-pointer">{{ category }}</span>
              </div>
            <!-- </router-link> -->
          </div>
      </div>
    </div>
    <div v-if="!searchResults.length || preventDisplay" class="center-screen text-center">
      <div>
        <img src="@/assets/logo/harhita_jain_logo_transparent.png" width="70" alt="">        
      </div>
        <p class="text-muted">
            <em>Search results will appear here</em>
        </p>
    </div>    
    <!-- <div v-for="result in searchResults" :key="result.id">
      <img :src="result.images[0]" alt="">
      {{ result.name }}
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { productsRef } from "./../services/database";
// import shortid from 'shortid'

export default {
  data() {
    return {
      searchResults: [],
      startSearch: false,
      searchResultsObj: {},
      currentKey: '',
      newSearchText: '',
      itemsLoaded: false,
      firstLoad: true,
      preventDisplay: false
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(from)
    next(vm => {      
      // vm.fromProduct = from.name == "SingleProduct";
    });
  },  
  beforeRouteLeave(to, from, next) { 
    this.$store.commit("ACTIVE_SEARCH_FIELD", false);    
    next();
  },
  created() {
    this.$store.commit("ACTIVE_SEARCH_FIELD", true);  
    if(this.searchText.length >= 3) {
      this.fetchResults()
    }  
  },
  computed: {
    searchText() {
      return this.$route.query.query;
    },
    categories() {
      let _categories = this.searchResults.map(item => item.category)
      return [...new Set([].concat(..._categories))]
    }
    // filteredSearchResults() {
    //   return !!this.currentKey? Array.from(new Set(this.searchResultsObj[this.currentKey].map((item) => item.id))) : []
    // }
  },
  methods: {
    async fetchResults() {
        let _searchText = this.searchText
        this.searchResults.length = 0
        axios
          .get(
            // "https://asia-east2-harshita-jain.cloudfunctions.net/searchItems?query=" +
            "https://harshita-jain.df.r.appspot.com/search?query=" +
              this.searchText
          )
          .then((response) => {            
            return Promise.resolve(response.data.results)
          })
          .then(async productNames => {  
            // this.itemsLoaded = false
            
            for (let name of productNames) {             
              let snap = await productsRef.where("name", "==", name).get();
              this.searchResults.push(snap.docs[0].data());                    
            }            
            if(_searchText !== this.searchText) {
              this.fetchResults()
              this.itemsLoaded = false
            } else {
              this.itemsLoaded = true
            }     
            // if(_searchText !== this.searchText) {
            //   this.fetchResults()
            //   this.itemsLoaded = false
            // }            
          })
          .catch(function(error) {
            console.log(error);
          });      
    },
    goToCategory(category) {
      let slug = `/categories/`+category.trim().split(' ').join('-').toLowerCase()
      this.$router.replace(slug)
    }
  },
  watch: {
    async searchText(val) {      
      if(this.searchText.length == 3) {
        this.preventDisplay = false
      }

      if (this.searchText.length >= 3) {
        if(this.firstLoad || this.itemsLoaded) {
          this.itemsLoaded = false
          this.firstLoad = false
          this.fetchResults()
        } else {
          console.log("not ready")
          this.newSearchText = val
          return
        }
      } else {
        this.preventDisplay = true
      }
    },
  },
};
</script>

<style scoped>
.center-screen {
  position: fixed;
  top:0;
  left:0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;  
  flex-direction: column;
}

.search {
  margin-top: 120px;
  margin-bottom: 40px;
  padding: 0 7%;    
  /* min-height: 80vh; */
}
.product-card {
  height: 750px;
  width: 100%;
  background: transparent;
  margin-bottom: 20px;
  overflow: hidden;
}

.product-image {
  height: 684px;
  background: rgb(255, 245, 231);
  overflow: hidden;
}

.product-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.product-info > .name {
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  /* width: 40px;   */
}

.lazy-img[lazy="loaded"] {
  opacity: 0;
  animation-name: fadein;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-timing-function: ease-out;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 786px) {
  .search {
    margin-top: 100px;
  }

  .product-card {
    height: 280px;
    width: 100%;
    background: transparent;
    margin-bottom: 15px;
    overflow: hidden;
  }

  .product-image {
    height: 220px;
    background: rgb(255, 245, 231);
    overflow: hidden;
  }

  .product-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .product-info .name {
    font-size: 14px;
  }

  .product-info .price {
    font-size: 12px;
  }
}
</style>
