import Vue from "vue";
import Vuex from "vuex";
import { listProducts, getProduct } from "./../api/products";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "harshita-jain",
  storage: window.localStorage,
  reducer: (state) => ({
    cart: state.cart,
    personInfo: state.personInfo,
    currency: state.currency
  }),
});

// required store changes

// new state variable - discount (will contain the exact discount object as in firestore)

// new getters
// discountAmount - will calculate the discount using discount object and cartotal as input
// subTotal - will return subtotal i.e. cartTotal - discountAmount

// new mutations
// SET_DISCOUNT() - will accept the firestore discount object as parameter and assign it to store discount state


export default new Vuex.Store({
  state: {
    loading: false,
    disableLoading: false,
    showPreloader: false,
    activeSearchField: false,
    cart: [],
    fullscreen: false,
    dropdown: {
      women: [
        {
          name: "Lagengas",
          img: require("@/assets/collections/collection2.jpg"),
        },
        {
          name: "Kurtas",
          img: require("@/assets/collections/collection3.jpg"),
        },
        {
          name: "Tunics",
          img: require("@/assets/collections/collection4.jpg"),
        },
      ],
      men: [
        {
          name: "Shirt",
          img: require("@/assets/collections/collection2.jpg"),
        },
        {
          name: "Kurtas",
          img: require("@/assets/collections/collection2.jpg"),
        },
      ],
    },
    isNavStatic: false,
    products: [],
    currentProduct: {},
    activeCart: false,
    personInfo: null,
    currency: "INR",
    currencies: [{
      symbol: 'Rs.',
      name: 'INR',
      value: 1
    }],
    showChatCallout: true,
    discount: { applied: false }
  },
  getters: {
    loading(state) {
      return state.disableLoading ? false : state.loading;
    },
    fullscreen(state) {
      return state.fullscreen;
    },
    showPreloader(state) {
      return state.showPreloader
    },
    products(state) {
      return state.products;
    },
    currentProduct(state) {
      return state.currentProduct;
    },
    activeCart(state) {
      return state.activeCart;
    },
    dropdown(state) {
      return state.dropdown;
    },
    productselect: (state) => (type) => {
      return state.products.filter((e) => e.type == type);
    },
    categoryProducts: (state) => (category) => {
      return state.products.filter((product) =>
        product.category.map((item) => item.toLowerCase()).includes(category)
      );
    },

    currentCategoryTitle: (state) => {
      return state.currentCategoryTitle;
    },

    product: (state) => (slug) => {
      return state.products.find((product) => product.slug == slug);
    },

    cart(state) {
      return state.cart;
    },
    cartTotal(state) {
      var sum = 0;
      state.cart.forEach((item) => (sum += item.price * item.quantity));
      return sum;
    },
    grandTotal(state, getters) {
      return getters.cartTotal + getters.deliveryCharge - getters.discountAmount
    },
    isNavStatic(state) {
      return state.isNavStatic;
    },
    personInfo(state) {
      return state.personInfo;
    },
    currency(state) {
      return state.currency;
    },
    currencyObj(state) {
      return state.currencies.find(item => item.name == state.currency)
    },
    currencies(state) {
      return state.currencies
    },
    showChatCallout(state) {
      return state.showChatCallout
    },
    deliveryCharge(state, getters) {
      if(state.personInfo == null) return 0
      if ((!!state.personInfo.country && state.personInfo.country.trim().toLowerCase() == 'india') || getters.cartTotal >= 35000) {
        return 0
      }
      return getters.cartTotal <= 15000 ? 5000 : 8000
    },
    activeSearchField(state) {
      return state.activeSearchField
    },
    //  grandTotal(state){
    //   return state.grandTotal 
    //  },
    //  subtotal(state, getters){
    //   if(state.discount.applied == false) { return getters.cartTotal }
    //   return getters.cartTotal + getters.discountAmount
    //  },
    discount(state) {
      return state.discount
    },
    discountAmount(state, getters) {
      if (state.discount.applied == false) { return 0 }
      let discountAmount = getters.cartTotal * (state.discount.rate * 0.01)
      return discountAmount >= state.discount.maxDiscount? state.discount.maxDiscount : discountAmount 
    }
  },
  mutations: {
    SET_LOADING_STATE(state, data) {
      state.loading = data;
    },
    SET_FULLSCREEN_STATE(state, data) {
      state.fullscreen = data;
    },
    SET_DISABLE_LOADING(state, data) {
      state.disableLoading = data;
    },
    SET_SHOW_PRELOADER(state, data) {
      state.showPreloader = data;
    },
    SET_SHOW_CHAT_CALLOUT(state, data) {
      state.showChatCallout = data
    },
    SET_CURRENT_CURRENCY(state, data) {
      state.currency = data
    },
    SET_CURRENCIES(state, data) {
      state.currencies = data
    },
    SET_PRODUCTS(state, data) {
      state.products = data;
    },
    SET_CURRENT_PRODUCT(state, data) {
      state.currentProduct = data;
    },
    ADD_TO_CART(state, data) {
      state.cart = [...state.cart, data];
    },
    UPDATE_CART(state, id) {
      state.cart[id].quantity++;
    },
    EMPTY_CART(state) {
      state.cart = []
    },
    ACTIVE_CART(state, data) {
      state.activeCart = data;
    },
    QUANTITY_CART_ACTION(state, data) {
      var productIndex = state.cart.findIndex(
        (product) => product.id == data.id && product.size == data.productSize
      );

      if (data.action == "inc") {
        state.cart[productIndex].quantity++;
      } else {
        state.cart[productIndex].quantity--;
        if (state.cart[productIndex].quantity == 0) {
          state.cart.splice(productIndex, 1);
        }
      }
    },
    UPDATE_IS_NAV_STATIC(state, data) {
      state.isNavStatic = data;
    },
    CONTACT_INFORMATION(state, data) {
      state.personInfo = data;
    },
    ACTIVE_SEARCH_FIELD(state, data) {
      state.activeSearchField = data
    },
    // SET_GRAND_TOTAL(state, data){
    //   state.grandTotal = data
    // },    
    SET_DISCOUNT(state, data) {
      state.discount = data == null ? { applied: false } : { ...data, applied: true }
    }
  },
  actions: {
    addToCart({ commit }, item) {
      commit("ADD_TO_CART", item);
      return Promise.resolve();
    },
    updateCart({ commit }, itemId) {
      return commit("UPDATE_CART", itemId);
    },
    quantityCartAction({ commit }, data) {
      return commit("QUANTITY_CART_ACTION", data);
    },
    fetchProducts({ commit }, condition) {
      return listProducts(condition).then((res) => {
        commit("SET_PRODUCTS", res);
        return Promise.resolve(res);
      });
    },
    fetchProduct({ commit }, slug) {
      return getProduct(slug).then((res) => {
        commit("SET_CURRENT_PRODUCT", res);
        return Promise.resolve(true);
      });
    },
    personInfo({ commit }, data) {
      commit("CONTACT_INFORMATION", data);
      return Promise.resolve();
    },
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
