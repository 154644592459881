<template>
  <div class="flash-message text-center">
    <flickity ref="flickity" :options="flickityOptions" v-if="!!flash_messages.length">
      <div class="carousel-cell" v-for="(flash, index) in flash_messages" :key="index">
        <div>
          <strong>{{ flash.main_text }} </strong>
          <span>{{ flash.sub_text }}</span>
        </div>
      </div>
      
    </flickity>
  </div>
</template>

<script>
import axios from "axios";
import Flickity from "vue-flickity";

export default {
  components: {
    Flickity,
  },
  data() {
    return {
      flash_messages:[],
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        contain: true,
        wrapAround: true,
        autoPlay: true,
        // any options from Flickity can be used
      },
    };
  },
  async created(){
    let res = await axios.get('https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/flash_message.json')
    this.flash_messages = res.data
  }
};
</script>

<style scoped>
.carousel-cell {
  color: #56524d;
  min-height: 40px;
  width: 100%;
  /* text-align: center; */
  background-color: #d7cec2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 0;
}


.flash-message{
  position: absolute;
  top: 85px;
  width: 100%;
  z-index: 2;
  margin-bottom: 180px;
}

@media (max-width: 768px) {
  .flash-message {
    top: 56px;
  }

  .carousel-cell span {
    display: block;
    text-transform: capitalize;
    margin-top: 3px;
  }
}
</style>
