<template>
  <div class="cart-page">
    <div class="container">
      <div class="row">
          <div class="col-md-12 text-center">
            <h1>Cart</h1>
          </div>
      </div>
      <div class="row">
        <div class="col-md-7">
            <div class="cart-item" v-for="(item, index) in cart" :key="item.id + '-' + index">
                <div class="item-image">
                    <img :src="item.images[0]" alt="">
                </div>
                <div class="item-details">
                    <div>{{ item.name }}</div>
                    <div class="d-flex mt-2 item-sub-details justify-content-between">
                        <div>
                           <span style="font-weight: bold">Size</span> {{ item.size }}
                            <div class="actions mt-2">
                                <button class="cart-qty-btn" @click="dec(item.id, item.size)">-</button>
                                &nbsp;
                                <span>{{ item.quantity }}</span>
                                &nbsp;
                                <button class="cart-qty-btn" @click="inc(item.id, item.size)">+</button>
                            </div>
                        </div>
                        <div>
                            Rs{{ item.price }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5" style="position: relative">
            <div class="subtotal-wrapper">
                <div class="cart-subtotal d-flex justify-content-between">
                    <div>Subtotal</div>
                    <div>{{ cartTotal }}</div>
                </div>
                <hr>
                <router-link to="/address">
                    <button class="btn-default">CHECKOUT</button>
                </router-link>
                <span class="info text-center d-block mt-1">Shipping, taxes, and discount codes calculated at checkout.</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["cart", "cartTotal"]),
  },

  methods:{
        dec(id, size) {
      this.$store.dispatch("quantityCartAction", {
        id: id,
        action: "dec",
        productSize: size,
      });
    },
    inc(id, size) {
      this.$store.dispatch("quantityCartAction", {
        id: id,
        action: "inc",
        productSize: size,
      });
    },
  }
};
</script>

<style scoped>
.cart-page{
    min-height: 70vh;
}
h1{
    font-weight: normal;
}

.cart-item{
    border: 1px solid #eee;
    padding: 10px;
    display: flex;
}

.cart-item:not(:first-child){
    margin-top: 20px;
}

.item-image{
    background-color: #eee;
    height: 140px;
    flex: 1;
}

.item-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.item-details{
    flex: 5;
    padding: 5px;
}

.item-sub-details{
    font-size: 12px;
}

.cart-qty-btn{
    border: 1px solid #eee;

}

.subtotal-wrapper{
     position: sticky;
  position: -webkit-sticky;
    top: 10%;
}

.btn-default{
    border: none;
    outline: none;
    width: 100%;
    padding: 10px 0px;
    background-color: #D7CEC2;
    letter-spacing: 2px;
    color: #56524d;
    cursor: pointer;
}

.info{
    font-size: 12px;
}
</style>