import firebase from "./firebase";
import "firebase/firestore"

export const db = firebase.firestore()
export const firestore = firebase.firestore
export const incrementByOne = firestore.FieldValue.increment(1);
export const productsRef = db.collection("Products").where("isLive", "==", true)
export const categoriesRef = db.collection("categories").where("active", "==", true)
export const ordersRef = db.collection("Orders")
export const searchStoreRef = db.doc("Search/store")
export const productsBkpRef = db.collection("ProductsBkp")
export const carouselRef = db.collection("Carousel")
export const collectionsRef = db.collection("Collections")
export const searchRef = db.collection("Search")
export const couponsRef = db.collection("Coupons")
export const metaRef = db.collection("Meta")