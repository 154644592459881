<template>
  <div class="modal-wrapper" @click="dismissable? closeChart(): () => {}">
    <div class="modal" :style="{ backgroundColor: bgColor }" @click.stop>
      <div
        class="modal-header d-flex justify-content-between align-items-center"
      >
        <slot name="header"></slot>        
        <WindowClose v-if="dismissable" @click="closeChart()" />
      </div>
      <div class="modal-body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import WindowClose from 'vue-material-design-icons/WindowClose.vue'

export default {
  props: {
    dismissable: {
      default: true,
      type: Boolean
    },
    bgColor: {
      default: '#e3dcd4',
      type: String,
      required: false
    }
  },
  methods: {
    closeChart() {
      this.$emit("closeChart", false);
    },
  },
  components: {
    WindowClose    
  }
};
</script>

<style scoped>

.modal-wrapper{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 888;
  display: flex;
  align-items: center;
  background: #6b6761b0;      
}

.modal {
  /* position: absolute; */
  min-width: 500px;
  padding:10px 20px;
  margin: 0 auto;
  background-color: #e3dcd4;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  border-radius: 4px;
}

.modal-header {
  /* border-bottom: 1px solid grey; */
  margin-bottom: 10px;
}


@media(max-width: 768px){
  .modal{
    min-width: 80vw;
    max-width: 95vw;
  }
}
</style>
