import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Products from '../views/products'
import SingleProduct from '../views/products/SingleProduct'
import InnerZoom from '../views/InnerZoom.vue'
import Address from '../views/Address.vue'
import Contact from '../views/Contact.vue'
import Disclaimer from '../views/Disclaimer.vue'
import TermAndCondition from '../views/TermAndCondition.vue'
import ReturnPolicy from '../views/ReturnPolicy.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Shipping from '../views/Shipping.vue'
import ShippingPolicy from '../views/ShippingPolicy.vue'
import Categories from '../views/Categories.vue'
import Category from '../views/Category.vue'
import Jewellery from '../views/Jewellery.vue'
import Cart from '../views/Cart.vue'
import CheckoutSuccess from '../views/CheckoutSuccess.vue'
import CheckoutFailure from '../views/CheckoutFailure.vue'
import Search from '../views/Search.vue'
import PreviewImage from '../components/PreviewImage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/products/:slug',
    name: 'SingleProduct',
    component: SingleProduct,
    meta: {
      showFlashMessage: true
    }    
  },
  {
    path: '/innerzoom',
    name: 'InnerZoom',
    component: InnerZoom
  },
  {
    path: '/address',
    name: 'Address',
    component: Address,
    meta: {
      static: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      static: true
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta:{
      static: true
    }
  },
  {
    path: '/jewellery',
    name: 'Jewellery',
    component: Jewellery,
    meta: {
      static: true
    }  
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer,
    meta: {
      static: true
    }    
  },
  {
    path: '/terms-and-conditions',
    name: 'TermAndCondition',
    component: TermAndCondition,
    meta: {
      static: true
    }    
  },
  {
    path: '/return-policy',
    name: 'ReturnPolicy',
    component: ReturnPolicy,
    meta: {
      static: true
    }    
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      static: true
    }    
  },
  {
    path: '/shipping',
    name: 'Shipping',
    meta: {
      static: true
    },
    component: Shipping
  },
  {
    path: '/shipping-policy',
    name: 'ShippingPolicy',
    meta: {
      static: true
    },
    component: ShippingPolicy
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories
  },
  {
    path: '/categories/:slug',
    name: 'Category',
    component: Category,
    meta: {
      showFlashMessage: true
    }
  },  
  {
    path: '/collections/:slug',
    name: 'Collection',
    component: Category,
    meta: {
      showFlashMessage: true
    }
  },
  {
    path: '/products/:slug/preview',
    name: 'Product Image Preview',
    component: PreviewImage
  },
  {
    path: '/checkout-success',
    name: 'Checkout Success',
    component: CheckoutSuccess,
    meta: {
      static: true
    }    
  },
  {
    path: '/checkout-failure',
    name: 'Checkout Failure',
    component: CheckoutFailure,
    meta: {
      static: true
    }    
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      static: true
    }  
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      static: true
    },    
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/test',
    name: 'Test',
    meta: {
      static: true
    },    
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Test.vue')
  }  
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      
     return new Promise(async (res, err) => {
        setTimeout(() => {
          res(savedPosition)
        }, 500);
     })
    } else {
      return { x: 0, y: 0 };
    }
  }
})

export default router
